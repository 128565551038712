import styled from 'styled-components';
import colors from '../../utils/theme';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import LobbyViewHeader from './LobbyViewHeader';
import IUserData from '../../@types/IUserData';
import ChannelSegment from './ChannelSegment';
import IAppState from '../../@types/IAppState';
import IChannel from '../../@types/IChannel';
import { Translate } from '../../utils/i18nManager';
import { CheckInChannel } from '../../utils/AmityManager';
import { DoesModeAllow } from '../../utils/ModeManager';

const LobbyViewContainer = styled.div<{
  inChat: boolean
}>`
  position: relative;
  z-index: 20;
  background: ${colors.black};
  width: 100vw;
  height: calc(100vh - 56px);
  overflow-y: auto;
  transition: all 333ms;
  overflow-y: auto;
  transform: translateX(${p => p.inChat ? '-100%' : '0%'});
  box-shadow: 0 0 8px ${colors.black}${p => p.inChat ? '00' : 'FF'};

`

const LobbyViewWrapper = styled.div<{
  showNotifications?: boolean
}>`
  position: absolute;
  bottom: 0; 
  left: 0;
  right: 0;
  top: 0;
  transition: all 333ms;

`

type Props = {
  appState: IAppState
  setAppState: Dispatch<SetStateAction<IAppState>>
}

type INotification = {
  channelId: string
  new: number
}

const LobbyView = (props: Props) => {

  let {
    appState,
    setAppState
  } = props;

  const [showNotifications, setShowNotifications] = useState<boolean>(false); // userData.settings.showNotifications
  const [notifications, setNotifications] = useState<INotification[]>([]);


  /**
   * Counts notifications from state.
   * @param chatId Chatroom ID
   * @returns number
   */
  const GetNotificationCount = (chatId: string): number => {
    let count: number = 0;
    notifications.forEach(ntx => {
      if(ntx.channelId === chatId) {
        count = ntx.new;
      }
    })
    return count;
  }

  /**
   * Checks if an unread message is a call request
   * @param channelId Channel ID
   * @returns boolean 
   */
  // const IsRequestingCall = (channelId: string): boolean => {
  //   let requesting: boolean = false;
  //   notifications.forEach(ntx => {
  //     if(ntx.channelId === channelId) {
  //       requesting = ntx.calling
  //     }
  //   })
  //   return requesting;
  // }

  /**
   * Connects to a chatroom.
   * @param chatId Chatroom ID
   */
  const JoinRoom = async (chatId: string) => {
    ClearNotifications(chatId);
    setAppState(prev => {
      let asx: IAppState = {...prev}
      asx.loading = true;
      return asx;
    })
    CheckInChannel(chatId, appState.userData!).then((res: any) => {
      setAppState(prev => {
        let asx = {...prev}
        asx.currently.inRoom = chatId;
        asx.loading = false;
        asx.checkins = {...res};
        return asx; 
      });
    })
  }

  /**
   * Clears notifications for given room.
   * @param chatId 
   */
  const ClearNotifications = (chatId: string) => {
    let nts = [...notifications];
    nts.forEach(ntx => {
      if(ntx.channelId === chatId) {
        ntx.new = 0;
        // ntx.calling = false;
      }
    })
    setNotifications(nts);
  }

  return (
    <LobbyViewContainer inChat={appState.currently.inRoom !== null}>
      <LobbyViewWrapper showNotifications={showNotifications}>
        {DoesModeAllow(appState.banterMode as string, 'showChannels') &&
          <>
            <LobbyViewHeader text={Translate('channels', appState.language)}/>
            {appState.channels
            // .sort((a, b) => a.lastActivity - b.lastActivity)
            .filter(a => a.type === 'community')
            .sort((a, b) => {
              let aPriority = a.metadata?.priority ?? 0;
              let bPriority = a.metadata?.priority ?? 0;
              return aPriority - bPriority || new Date(a.lastActivity as string).getTime() - new Date(b.lastActivity as string).getTime()
            })
            .reverse()
            .map((chx, i) => {
              if(chx.isDeleted || chx.isMuted) return null;
              return (
                <ChannelSegment
                  key={i}
                  appState={appState}
                  channelData={chx}
                  newMessages={GetNotificationCount(chx.channelId)}
                  // requestingCall={IsRequestingCall(crx.id)}
                  onJoin={() => JoinRoom(chx.channelId)}
                />
              ) 
            })}
          </>
        }
        <LobbyViewHeader text={Translate('direct-messages', appState.language)}/>
        <>
          {appState.channels
          .filter(a => a.type === 'live')
          .sort((a, b) => new Date(a.lastActivity as string).getTime() - new Date(b.lastActivity as string).getTime())
          .map((chx, i) => {
            if(chx.isDeleted || chx.isMuted) return null;
            return (
              <ChannelSegment
                key={i}
                appState={appState}
                channelData={chx}
                newMessages={GetNotificationCount(chx.channelId)}
                // requestingCall={IsRequestingCall(crx.id)}
                onJoin={() => JoinRoom(chx.channelId)}
              />
            ) 
          })}
        </>
      </LobbyViewWrapper>
    </LobbyViewContainer>
  )
}

export default LobbyView;
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GetUserStatus, GetUserStatusIcon, GetUserStatusText, UserStatus } from '../../utils/ChatUtils';
import colors from '../../utils/theme';

const UserItemContainer = styled.div`
  width: 100%;
  transition: all 222ms;
  cursor: pointer;

  &:hover {
    background: ${colors.deep};
  }
`

const UserItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
`

const UserProfilePictureWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
  margin: 0.75rem;
`

const UserProfilePictureImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const UserContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const UserNameWrapper = styled.div`
  height: 100%;
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  align-content: center;
`

const UserStatusWrapper = styled.div`
  width: 100%;
  font-size: 14px;
`

const UserStatusImageWrapper = styled.div`
  height: 22px;
  width: 22px;
  position: absolute;
  bottom: 4px;
  left: 48px;
  background: ${colors.black};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const UserStatusImage = styled.img`
  width: 14px;
  height: 14px;
`

type Props = {
  user: Amity.User
  onOpenContextMenu: () => void
  onSegmentClick: () => void
}

const UserSegment = (props: Props) => {

  let {
    user
  } = props;

  const [userImage, setUserImage] = useState<string>('https://inferno-event-assets.s3.us-west-2.amazonaws.com/misc/jtools/blank-profile-picture.jpg');

  // useEffect(() => {
  //   let testUrl = `https://prod-eastus-ingress.inferno.jolokia.com/api/ProfileImage/${props.user.userId}/256/256`
  //   let image = new Image();
  //   image.onload = () => setUserImage(testUrl);
  //   image.src = testUrl;
  // }, [props])

  return(
    <UserItemContainer onClick={() => props.onSegmentClick()}>
      <UserItemWrapper>
        <UserProfilePictureWrapper onClick={(e) => {
          e.stopPropagation();
          props.onOpenContextMenu()
        }}>
          <UserProfilePictureImg src={userImage} />
        </UserProfilePictureWrapper>
        <UserContentWrapper>
          <UserNameWrapper>
            {user?.metadata?.nickname ?? user.displayName}
          </UserNameWrapper>
          <UserStatusWrapper>
            {GetUserStatusText(user)} 
          </UserStatusWrapper>
        </UserContentWrapper>
        <UserStatusImageWrapper>
          <UserStatusImage src={GetUserStatusIcon(GetUserStatus(user) as UserStatus)} alt='' />
        </UserStatusImageWrapper>
      </UserItemWrapper>
    </UserItemContainer>
  )
}

export default UserSegment;
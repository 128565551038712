import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components'
import IAppState from '../@types/IAppState';
import IChannel from '../@types/IChannel';
import { GetCurrentRoomName } from '../utils/ChatUtils';
import { Translate } from '../utils/i18nManager';
import colors from '../utils/theme';
import FormButton from './inputs/FormButton';
import FormInput from './inputs/FormInput';

const ClickTarget = styled.div<{
  active: boolean
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: ${colors.black}88;
  backdrop-filter: blur(3px);
  transition: all 222ms;
  opacity: ${p => p.active ? '1' : '0'};
  pointer-events: ${p => p.active ? 'all' : 'none'}
`

const Container = styled.div<{
  active: boolean
}>`
  position: absolute;
  transition: all 333ms;
  top: 0;
  bottom: 0;
  right: ${p => p.active ? '0%' : '0%'};
  opacity: ${p => p.active ? '1' : '0'};

  width: ${p => p.active ? '90%' : '60%'};
  max-width: 300px;
  height: 100%;

  background: ${colors.white};
  box-shadow: 0 -4px 8px ${colors.black}88;
  z-index: 100;
  overflow: hidden;
  pointer-events: ${p => p.active ? 'all' : 'none'}
`

const Wrapper = styled.div`
  width: 90vw;
  max-width: 300px;
  height: 100%;

`

const ChatNameHeader = styled.div`
  width: calc(100% - 2rem);
  padding: 1rem;
  font-size: 1.5rem;
  color: ${colors.white};
  background: ${colors.black};
  border-bottom: 1px solid ${colors.black}33;
  text-align: center;
  box-shadow: 0 3px 4px ${colors.black}88;
  font-weight: 500;
`

const SettingHeader = styled.div`
  width: 100%;
  height: 24px;
  margin: 0.5rem 1rem;
  font-size: 1.25rem;
  font-weight: 500;
`

const ChatImageContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const ChatImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 3px 12px ${colors.primary}55;
`

const ChatImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

type Props = {
  appState: IAppState,
  setAppState: Dispatch<SetStateAction<IAppState>>
}

const ChatSettingsNav = (props: Props) => {

  let [currentChannel, setCurrentChannel] = useState<Amity.Channel>();

  let { 
    appState,
    setAppState
  } = props;

  let { editingRoom } = appState.currently

  useEffect(() => {
    if (currentChannel?.channelId !== appState.currently.inRoom) {
      appState.channels.map(channel => {
        if(channel.channelId === appState.currently.inRoom) {
          setCurrentChannel(channel);
        }
      })
    }
  }, [appState]);

  const CloseMenu = () => {
    setAppState(prev => {
      let asx = {...prev}

      asx.currently.editingRoom = false;

      return asx;
    })    
  }

  const UpdateChatName = (e: any) => {
    setAppState(prev => {
      let asx = {...prev}
      asx.channels.map(channel => {
        if(channel.channelId === currentChannel!.channelId) {
          channel.displayName = e.target.value;
        }
      })
      return asx;
    })
  }

  return (
    <>
      <ClickTarget onClick={CloseMenu} active={editingRoom}/>
      <Container active={editingRoom}>
        <Wrapper>
          <ChatNameHeader>{Translate('chat-settings', appState.language)}</ChatNameHeader>

          <SettingHeader>{Translate('chat-appearance', appState.language)}</SettingHeader>

          <ChatImageContainer>
            <ChatImageWrapper>
              <ChatImage src={currentChannel?.metadata?.avatarURL} alt='' />
            </ChatImageWrapper>
          </ChatImageContainer>
          
          <FormInput
            placeholder={Translate('chat-name', appState.language)}
            value={currentChannel?.displayName ?? ''}
            autoComplete={'no'}
            onChange={UpdateChatName}
          />

          <SettingHeader>{Translate('advanced', appState.language)}</SettingHeader>
          <FormButton type={'danger'}>
            {Translate('clear-chat-history', appState.language)}
          </FormButton>
        </Wrapper>
      </Container>
    </>
  )
}

export default ChatSettingsNav;
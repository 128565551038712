import styled from 'styled-components';
import colors from '../../utils/theme';

const SectionHeader = styled.div`
  width: 100%;
  margin: 1rem 0 0.5rem 0;
  display: flex;
  align-content: center;
  align-items: center;
`

const SectionText = styled.div`
  margin-left: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
`

type Props = {
  text: string
}

const LobbyViewHeader = (props: Props) => {

  return (
    <SectionHeader>
      <SectionText>{props.text}</SectionText>
    </SectionHeader>
  )
}

export default LobbyViewHeader;
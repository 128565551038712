import { useState } from 'react';
import styled from 'styled-components';
import colors from '../../utils/theme';
import SwitchSlider from './SwitchSlider';

const SwitchInputContainer = styled.div`
  width: calc(100% - 0.5rem);
  height: 56px;
`

const SwitchInputWrapper = styled.div`
  margin: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`

const SwitchInputText = styled.div`
  white-space: pre-line;
`

const SwitchInputFollowThrough = styled.div`
  height: 0px;
  border-top: 1px dashed ${colors.black}33;
  flex-grow: 2;
  margin: 0 1rem;
`

type Props = { 
  label: string
  loading?: boolean
  title?: string
  value: boolean
  disabled?: boolean
  onChange: () => void
}

const SwitchInput = (props: Props) => {

  let [descriptionActive, setDescriptionActive] = useState<boolean>(false);

  return(
    <SwitchInputContainer>
      <SwitchInputWrapper title={props.title}>
          <SwitchInputText onClick={() => {setDescriptionActive(!descriptionActive)}}>
            {props.label}
          </SwitchInputText>
        <SwitchInputFollowThrough />
        <SwitchSlider
          disabled={props.disabled}
          loading={props.loading}
          value={props.value}
          onChange={props.onChange}
        />
      </SwitchInputWrapper>
    </SwitchInputContainer>
  )

}

export default SwitchInput;
import { i18nDictionary } from "./i18nDictionary"

export const Translate = (key: string, lang: string): string => {
  if (i18nDictionary[key] && i18nDictionary[key][lang]) {
    return i18nDictionary[key][lang]
  } else {
    return i18nDictionary[key]['en'] ?? 'unnamed'
  }
}

export const SupportedLanguages = (lang: string): string[] => {
  return ['']
}

/**
 * Checks for lang= URL param first. 
 * Then checks for Navigator language.
 * Then fails to 'en'
 */
export const DetectLanguage = () => {
  let params = Object.fromEntries(new URLSearchParams(window.location.search));
  if (params.lang) {
    return params.lang;
  }

  if (navigator.language.substr(0, 2)) {
    return navigator.language.substr(0, 2);
  }

  return 'en';
  
}
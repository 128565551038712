interface Ii18nDictionary {
  [key: string]: {
    [lang: string]: string
  }
}
/**
 *** Supported Languages:
 * English | 100% (Verified)
 * French | Near Completion
 * Spanish | Near Completion
 * Russian | Near Completion
 * Chinese | Near Completion
 * Japanese | Near Completion
 * German | Near Completion
 * Hindi | Near Completion
 * 
 *** Coming Eventually:
 * Arabic
 * Farsi
 * Persian
 * Punjabi
 * Cantonese
 * Indonesian
 * Hebrew
 * Portuguese
 * Greek
 * Italian
 * 
 */
export const i18nDictionary: Ii18nDictionary = {
  // Chat UI
  'loading-connecting': {
    'en': 'Getting connected...',
    'fr': 'Se connecter...',
    'es': 'Conectándose...',
    'ru': 'Подключение ...',
    'zh': '连接...',
    'ja': '接続しています...',
    'de': 'Verbinden...',
    'hi': 'जोड़ने...',
  },
  'messages': {
    'en': 'Messages',
    'fr': 'Messages',
    'es': 'Mensajes',
    'ru': 'Сообщения',
    'zh': '消息',
    'ja': 'メッセージ',
    'de': 'Mitteilungen',
    'hi': 'संदेशों',
  },
  'channels': {
    'en': 'Channels',
    'fr': 'Canaux',
    'es': 'Canales',
    'ru': 'каналы',
    'zh': '频道',
    'ja': 'チャネル',
    'de': 'Kanäle',
    'hi': 'चैनलों',
  },
  'direct-messages': {
    'en': 'Direct Messages',
    'fr': 'Messages Directs',
    'es': 'Mensajes Directos',
    'ru': 'Прямые сообщения',
    'zh': '直接消息',
    'ja': 'ダイレクトメッセージ',
    'de': 'Direktnachrichten',
    'hi': 'सीधे संदेश',
  },
  'users': {
    'en': 'Users',
    'fr': 'Utilisateurs',
    'es': 'Usuarios',
    'ru': 'Пользователи',
    'zh': '用户',
    'ja': 'ユーザー',
    'de': 'Benutzer',
    'hi': 'उपयोगकर्ताओं',
  },
  'search': {
    'en': 'Search',
    'fr': 'Chercher',
    'es': 'Buscar',
    'ru': 'Поиск',
    'zh': '搜索',
    'ja': '探す',
    'de': 'Suche',
    'hi': 'खोज',
  },
  'write-message': {
    'en': 'Write Message',
    'fr': 'Écrire un message',
    'es': 'Escribe un mensaje',
    'ru': 'Напиши сообщение',
    'zh': '写信息',
    'ja': 'メッセージを書く',
    'de': 'Nachricht schreiben',
    'hi': 'संदेश लिखो',
  },
  'end-of-history': {
    'en': 'End of History', 
    'fr': 'Fin de l\'histoire',
    'es': 'Fin de la historia',
    'ru': 'Конец истории',
    'zh': '历史的终结',
    'ja': '歴史の終わり',
    'de': 'Ende der Geschichte',
    'hi': 'चैट इतिहास का अंत',
  },
  'close-menu': {
    'en': 'Close Menu',
    'fr': 'Fermer le menu',
    'es': 'Cerrar el menú',
    'ru': 'Закрыть меню',
    'zh': '关闭菜单',
    'ja': 'メニューを閉じる',
    'de': 'Menü schließen',
    'hi': 'मेनू बंद करें',
  },
  'loading': {
    'en': 'Loading',
    'fr': 'Chargement',
    'es': 'Cargando',
    'ru': 'Загрузка',
    'zh': '加载',
    'ja': '読み込み中',
    'de': 'Wird geladen',
    'hi': 'लोड हो रहा है',
  },
  'anonymous': {
    'en': 'Anonymous',
    'fr': 'Anonyme', 
    'es': 'Anónimo',
    'ru': 'Анонимный',
    'zh': '匿名的',
    'ja': '匿名',
    'de': 'anonym',
    'hi': 'अनाम',
  },
  'token-error': {
    'en': 'Invalid Token',
    'fr': 'Token invalide',
    'es': 'Token no válido',
    'ru': 'Неверный токен',
    'zh': '令牌无效',
    'de': 'Ungültiges Token',
    'hi': 'अमान्य टोकन',
  },
  'contact-support': {
    'en': 'If you\'re seeing this, contact system support.',
    'fr': 'Si vous voyez cela, contactez l\'assistance système.',
    'es': 'Si ve esto, comuníquese con el soporte del sistema.',
    'ru': 'Если вы видите это, обратитесь в службу поддержки системы.',
    'zh': '如果您看到此信息，请联系系统支持。',
    'ja': 'これが表示された場合は、システムサポートに連絡してください。',
    'de': 'Wenn Sie dies sehen, wenden Sie sich an den Systemsupport.',
    'hi': 'यदि आप इसे देख रहे हैं, तो सिस्टम समर्थन से संपर्क करें।',
  },
  'uh-oh': {
    'en': 'Uh-oh!',
    'fr': 'Oups!',
    'es': '¡Uh oh!',
    'ru': 'Ой!',
    'zh': '哦哦！',
    'ja': 'ええとああ！',
    'de': 'Hoppla',
    'hi': 'उफ़',
  },
  'something-went-wrong': {
    'en': 'Something went wrong',
    'fr': 'Une erreur s\'est produite',
    'es': 'Algo salió mal',
    'ru': 'Что-то пошло не так',
    'zh': '出了些问题',
    'ja': '何かが間違っていました',
    'hi': 'कुछ गलत हो गया',
  },
  'reload': {
    'en': 'Reload',
    'fr': 'Rafraîchir',
    'es': 'Recargar',
    'ru': 'Обновить',
    'zh': '刷新',
    'ja': 'ページの更新',
    'de': 'Aktualisierung',
    'hi': 'पृष्ठ ताज़ा करें',
  },
  'user-error': {
    'en': 'User Data Error',
    'fr': 'Erreur de données utilisateur',
    'es': 'Error de datos de usuario',
    'ru': 'Ошибка данных пользователя',
    'zh': '用户数据错误',
    'ja': 'ユーザーデータエラー',
    'de': 'Benutzerdatenfehler',
    'hi': 'उपयोगकर्ता डेटा त्रुटि',
  },

  // Profile
  'admin': {
    'en': 'Admin',
    'fr': 'Administrateur',
    'es': 'Administrador',
    'ru': 'Администратор',
    'zh': '行政人员',
    'ja': '管理者',
    'de': 'Administrator',
    'hi': 'प्रशासक',
  },
  'moderator': {
    'en': 'Moderator',
    'fr': 'Modérateur',
    'es': 'Moderador',
    'ru': 'Модератор',
    'zh': '版主',
    'ja': 'モデレータ',
    'de': 'Moderator',
    'hi': 'मध्यस्थ',
  },
  'user': {
    'en': 'User',
    'fr': 'Utilisateur',
    'es': 'Usuario',
    'ru': 'Пользователь',
    'zh': '用户',
    'ja': 'ユーザー',
    'de': 'Benutzer',
    'hi': 'उपयोगकर्ता',
  },
  'profile-name': {
    'en': 'Profile Name',
    'fr': 'Nombre de perfil',
    'es': 'Nom de profil',
    'ru': 'Имя профиля',
    'zh': '用户',
    'ja': 'プロファイル名',
    'de': 'Profilname',
    'hi': 'प्रोफ़ाइल नाम',
  },
  'nickname': {
    'en': 'Nickname',
    'fr': 'Surnom',
    'es': 'Apodo',
    'ru': 'Ник',
    'zh': '昵称',
    'ja': 'ニックネーム',
    'de': 'Spitzname',
    'hi': 'उपनाम',
  },

  // Profile Settings
  'display-name-settings': {
    'en': 'Display Name Settings',
    'fr': 'Paramètres du nom',
    'es': 'Configuración de nombre',
    'ru': 'Настройки имени',
    'zh': '显示名称设置',
    'ja': '表示名の設定',
    'de': 'Namenseinstellungen',
    'hi': 'प्रदर्शन नाम सेटिंग्स',
  },
  'display-title': {
    'en': 'Nickname',
    'fr': 'Afficher le titre',
    'es': 'Mostrar título',
    'ru': 'Ник',
    'zh': '昵称',
    'ja': 'ニックネーム',
    'de': 'Spitzname',
    'hi': 'उपनाम',
  },
  
  // App Settings
  'notification-settings': {
    'en': 'Notifications',
    'fr': 'Notifications',
    'es': 'Notificaciones',
    'ru': 'Уведомления',
    'zh': '通知设置',
    'ja': '通知',
    'de': 'Benachrichtigungen',
    'hi': 'सूचनाएं',
  },
  'notification-sounds': {
    'en': 'Notification Sound',
    'fr': 'Son de notification',
    'es': 'Sonido de notificación',
    'ru': 'Звуки уведомлений',
    'zh': '通知声音',
    'ja': '通知音',
    'hi': 'अधिसूचना लगता है',
  },
  'app-settings': {
    'en': 'App Settings',
    'fr': 'Paramètres de l\'application',
    'es': 'Ajustes de Aplicacion',
    'ru': 'Настройки приложения',
    'zh': '应用程序设置',
    'ja': 'アプリケーションの設定',
    'de': 'Anwendungseinstellungen',
    'hi': 'एप्लिकेशन सेटिंग',
  },
  'auto-scroll-to-bottom': {
    'en': 'Auto-Scroll',
    'fr': 'Défilement automatique',
    'es': 'Desplazamiento automático',
    'ru': 'Автопрокрутка',
    'zh': '自动滚屏',
    'ja': '自動スクロール',
    'de': 'Auto Scroll',
    'hi': 'स्वतः स्क्रॉल',
  },

  // Chat Settings
  'chat-settings': {
    'en': 'Chat Settings',
    'fr': 'Paramètres',
    'es': 'Configuraciones',
    'ru': 'настройки чата',
    'zh': '聊天设置',
    'ja': 'チャット設定',
    'de': 'Chat Einstellungen',
    'hi': 'चैट सेटिंग्स',
  },
  'chat-appearance': {
    'en': 'Appearance',
    'fr': 'Apparence',
    'es': 'Apariencia',
    'ru': 'внешность',
    'zh': '外貌',
    'ja': '外観',
    'de': 'Chat-Auftritt',
    'hi': 'दिखावट',
  },
  'chat-name': {
    'en': 'Chat Name',
    'fr': 'Nom du chat',
    'es': 'Nombre de chat',
    'ru': 'Имя в чате',
    'zh': '聊天名称',
    'ja': 'チャット名',
    'de': 'Chat Name',
    'hi': 'चैट नाम',
  },
  'advanced': {
    'en': 'Advanced',
    'fr': 'Avancée',
    'es': 'Avanzado',
    'ru': 'передовой',
    'zh': '高级设置',
    'ja': '高度な設定',
    'de': 'Erweiterte Einstellungen',
    'hi': 'उन्नत',
  },
  'clear-chat-history': {
    'en': 'Clear Chat History',
    'fr': 'Effacer l\'historique',
    'es': 'Borrar historial',
    'ru': 'удалить историю сообщений',
    'zh': '清除聊天记录',
    'ja': '明確なチャット履歴',
    'de': 'Chatverlauf löschen',
    'hi': 'बातचीत इतिहास हटाएं',
  },

}
import styled, { CSSProperties } from 'styled-components';
import IAppState from '../../@types/IAppState';
import GooeyRingSpinner from '../../media/images/GooeyRingSpinner.svg'
import { Translate } from '../../utils/i18nManager';
import colors from '../../utils/theme';

const LoadingContainer = styled.div<{
  active: boolean
}>`
  width: 100%;
  height: 100%;
  opacity: ${p => p.active ? '1': '0'};
  pointer-events: ${p => p.active ? 'all' : 'none'};
  position: absolute;
  background: ${colors.deep}88;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 222ms;
  z-index: 9999;
`

const LoadingWrapper = styled.div<{
  active: boolean
}>`
  width: 100%;
  height: ${p => p.active ? '100%' : '50%'};
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const SpinnerImage = styled.img`
  width: 100px;
  height: 100px;
`

const LoadingText = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${colors.white}88;
  text-align: center;
`

type Props = {
  appState: IAppState
}

const LoadingPanel = (props: Props) => {
  return (
    <LoadingContainer active={props.appState.loading}>
      <LoadingWrapper active={props.appState.loading}>
        <SpinnerImage src={GooeyRingSpinner} alt='' />
        <LoadingText>{Translate('loading', props.appState.language)}...</LoadingText>
      </LoadingWrapper>
    </LoadingContainer>
  )
}

export default LoadingPanel;
import styled, { keyframes } from 'styled-components';
import AppSettings from '../../utils/settings';
import colors from '../../utils/theme';

import MultiUserIcon from '../../media/icons/multiusers.svg';
import { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import IAppState from '../../@types/IAppState';
import { CheckRoomState } from '../../utils/ARoomIntegration';

const SegmentContainer = styled.div<{
  online: boolean
}>`
  width: calc(100% - 1rem);
  transition: all 222ms;
  padding: 0.5rem;
  height: ${p => p.online ? '56px' : '0px'};
  opacity: ${p => p.online ? '1' : '0'};

  &:hover {
    transition: all 111ms;
    background: ${colors.deep}11;
  }
`

const SegmentWrapper = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  transition: all 222ms;
  cursor: pointer;
`

const SegmentImageWrapper = styled.div`
  height: 42px;
  width: 42px;
  min-width: 42px;
  margin: 0 0.5rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 4px ${colors.primary}44;
  background: ${colors.white};
  border: 1px solid ${colors.black}33;
`

const SegmentImage = styled.img`
  width: 100%;
  height: 100%;
`

const SegmentContentCenter = styled.div`
  width: calc(100% - 200px);
  display: flex;
  flex-grow: 2;
  flex-wrap: wrap;
`

const SegmentContentRight = styled.div`
  width: 80px;
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  padding-right: 1rem;
`

const ChannelText = styled.div`
  height: 22px;
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
`

const ChannelName = styled(ChannelText)`
  font-size: 16px;
`

const LastMesage = styled(ChannelText)<{
  bold: boolean
}>`
  font-size: 13px;
  display: block;
  margin-bottom: -4px;
  white-space: nowrap;
  overflow: hidden;
  ${p => p.bold ? 'font-family: ProximaNovaBold;' : ''}
  text-overflow: ellipsis;
`
const LastTime = styled(ChannelText)<{
  bold: boolean
}>`
  ${p => p.bold ? 'font-family: ProximaNovaBold;' : ''}
  justify-content: flex-end;
  font-size: 13px;
`

const NotificationBubble = styled(ChannelText)`
  justify-content: flex-end;
  font-size: 13px;
  width: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin-left: 0.5rem;
  background: ${colors.primary};
`

const NotificationCallBubble = styled(ChannelText)`
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  border-radius: 100% !important;
  justify-content: center;
  align-items: center;
  border-radius: 0; 
  background: ${colors.primary};
`

const NotificationCallBubblePulseAnim = keyframes`
  0% {
    width: 100%;
    height: 100%;
    transform: scale(1);
    opacity: 1; 
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0; 
  }
`

const NotificationCallBubblePulse = styled.div`
  position: absolute;
  animation: ${NotificationCallBubblePulseAnim} 2s linear infinite;
  border-radius: 100%;
  background: ${colors.primary};
`

const NotificationCallIcon = styled.img`
  height: calc(100% - 16px);
  height: calc(100% - 16px);
  filter: invert(1) drop-shadow(0 0 2px #00000033);
  object-fit: contain;
`

type Props = {
  channelData: Amity.Channel
  newMessages: number
  // requestingCall: boolean
  appState: IAppState
  onJoin: () => void
}

const RoomSegment = (props: Props) => {

  let {
    channelData,
    appState
  } = props;

  const [userImage, setUserImage] = useState<string>('https://inferno-event-assets.s3.us-west-2.amazonaws.com/misc/jtools/blank-profile-picture.jpg');
  const notificationCount = useRef<number>(0);
  const [callActive, setCallActive] = useState<boolean>(false);

  // Checks if the user has a recent checkin for that channel.
  const HasCheckedIn = (): boolean => {
    let message: Amity.Message = appState.messages.filter((a) => a.channelId === channelData.channelId).sort((a, b) => {
      return new Date(a.createdAt as string).getTime() - new Date(b.createdAt as string).getTime()
    }).reverse()[0];
    let checkin = new Date();
    if(message && appState.userData?.metadata) {
      checkin = new Date(appState?.userData?.metadata[message.channelId]);
    }
    if (message && checkin) {
      return new Date(checkin).getTime() > new Date(message.createdAt).getTime();
    } else {
      return true;
    }
  }

  // Get the content of the last message in that channel.
  const LastMessage = (): string => {
    let lastMessageText = 'No Messages'
    let message: Amity.Message = appState.messages.filter((a) => a.channelId === channelData.channelId).sort((a, b) => {
      return new Date(a.createdAt as string).getTime() - new Date(b.createdAt as string).getTime()
    }).reverse()[0];
    if(message) {
      for (let usx of appState.users) {
        if (message.userId === usx.userId) {
          let username = usx.metadata?.nickname ?? usx.displayName
          let messageText = message.data.text;
          lastMessageText = `${username}: ${messageText}`
        }
      }
    }
    return lastMessageText;
  }

  // Get the last time a message was sent in that channel.
  const LastMessageTime = (): string => {
    let message: Amity.Message = appState.messages.filter((a) => a.channelId === channelData.channelId).sort((a, b) => {
      return new Date(a.createdAt as string).getTime() - new Date(b.createdAt as string).getTime()
    }).reverse()[0];
    if (message) {
      return DateTime.fromISO(message.createdAt).toLocaleString({'hour': '2-digit', 'minute': '2-digit', });
    } else {
      return '';
    }
  }

  // Get the channel name.
  const GetChannelName = (channel: Amity.Channel): string => {
    if (channel.type === 'live') {
      let channelName = 'Private Chat'
      // Get first person's name that isn't me
      for (let usx of appState.users) {
        if (channel.metadata?.between.includes(usx.userId) && appState.userData?.userId !== usx.userId) {
          channelName = usx.metadata?.nickname ?? usx.displayName;
        }
      }
      if (channel.metadata?.between.length > 2) {
        channelName += ` & ${channel.metadata?.between?.length} Other${channel.metadata?.between?.length > 3 ? 's' : ''}`;
      }
      return channelName;
    }

    return channel.displayName ?? 'Unnamed Channel';
  }

  useEffect(() => {
    if (channelData.metadata?.between?.length > 2) {
      setUserImage(MultiUserIcon); 
    }
    let testUrl = ''
    for (let usx of appState.users) {
      if (channelData.metadata?.between?.includes(usx.userId) && appState.userData?.userId !== usx.userId) {
        testUrl = `https://prod-eastus-ingress.inferno.jolokia.com/api/ProfileImage/${usx.userId}/256/256`
      }
    }
    let image = new Image();
    image.onload = () => setUserImage(testUrl);
    image.onerror = () => setUserImage('https://inferno-event-assets.s3.us-west-2.amazonaws.com/misc/jtools/blank-profile-picture.jpg');
    image.src = testUrl;
  }, [appState.channels])

  useEffect(() => {
    // console.log('Checking calls', appState.activeCalls);
    setCallActive(appState.activeCalls.includes(channelData.channelId));
    notificationCount.current = CalculateNotifications();
  }, [appState.messages, appState.activeCalls])

  const CalculateNotifications = (): number => {
    let count = 0;
    let { messages, userData } = props.appState;
    for (let msx of messages.filter(msg => msg.channelId === channelData.channelId)) {
      if (userData?.metadata?.checkins) {
        for (let cix of userData.metadata.checkins) {
          if (cix.channelId === channelData.channelId && new Date(msx.createdAt).getTime() > new Date(cix.checkin).getTime()) {
            count++;
          }
        }
      } else {
        return 0;
      }
    }
    return count;
  }

  return (
    <SegmentContainer
      online={true}
      onClick={props.onJoin}
    >
        <SegmentWrapper>
          <SegmentImageWrapper>
            {channelData.metadata?.avatarURL ?
              <SegmentImage src={channelData.metadata?.avatarURL} alt='' />
            : 
              <SegmentImage src={userImage} alt='' />
            }
          </SegmentImageWrapper>
          <SegmentContentCenter>
            <ChannelName>
              {GetChannelName(channelData)}
            </ChannelName>
            <LastMesage bold={HasCheckedIn()}>
              {LastMessage()}
            </LastMesage>
          </SegmentContentCenter>
          {callActive &&
            <NotificationCallBubble>
                <NotificationCallBubblePulse />
                <NotificationCallIcon src='https://www.svgrepo.com/show/374792/call.svg' />
            </NotificationCallBubble>
          }
          <SegmentContentRight>
            <LastTime bold={HasCheckedIn()}>
              {LastMessageTime()}
            </LastTime>
            
            {CalculateNotifications() > 0 &&
              <NotificationBubble>
                {CalculateNotifications()}
              </NotificationBubble>
            }
            
          </SegmentContentRight>
          
        </SegmentWrapper>
    </SegmentContainer>
  )
}

export default RoomSegment;
import { lighten } from "polished";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import IAppState from "../../@types/IAppState";
import colors from "../../utils/theme";

import FlagIcon from '../../media/icons/flag.svg';
import HammerIcon from '../../media/icons/hammer.svg';
import CancelIcon from '../../media/icons/close.svg';
import VideoIcon from '../../media/icons/video.svg';
import ChatIcon from '../../media/icons/chat.svg';
import { CreateConversation } from "../../utils/AmityManager";

const ContextMenuContainer = styled.div<{
  active: boolean
}>`
  position: absolute;
  bottom: 0; 
  left: 0; 
  right: 0;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  backdrop-filter: blur(2px);
  background: ${colors.black}88;
  transition: all 222ms;
  pointer-events: ${p => p.active ? 'all' : 'none'};
  overflow: hidden;
  opacity: ${p => p.active ? '1' : '0'};
`

const ContextMenuWrapper = styled.div<{
  active: boolean
}>`
  width: 100%;
  max-width: 350px;
  border-radius: 4px;
  overflow: hidden;
  background: ${colors.deep}; 
  color: ${colors.white};
  transition: all 222ms;
  transform: translateY(${p => p.active ? '0px' : '100px' });
`

const ContextMenuHeaderWrapper = styled.div`
  width: calc(100% - 2rem);
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid ${colors.gray}33;
`

const ContextMenuUserStatsWrapper = styled.div`
  width: calc(100% - 1rem);
  padding: 1rem;
  border-bottom: 1px solid ${colors.gray}33;
`

const ContextMenuActionWrapper = styled.div`
  width: calc(100% - 2rem);
  padding: 1rem;
  transition: all 111ms;
  cursor: pointer;
  border-bottom: 1px solid ${colors.gray}33;
  display: flex;

  &:hover {
    background: ${lighten(0.05, colors.deep)};
  } 
`

const ContextMenuActionIconWrapper = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`

const ContextMenuActionIconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: invert(1);
`

type Props = {
  appState: IAppState,
  setAppState: Dispatch<SetStateAction<IAppState>>
  updateRefs: (ref: 'users' | 'channels' | 'messages', newValue: any, type: 'combine' | 'overwrite', forceUpdate: boolean) => void
}

const UserContextMenu = (props: Props) => {
  let {
    appState,
    setAppState
  } = props;

  let contextedUser = appState.userContext;

  const GetActions = (): any[] => {
    let actions = [];
    let users: Amity.User[] = [];
    users.push(contextedUser!);
    actions.push({
      text: 'Start Conversation',
      icon: ChatIcon,
      onClick: () => {
        CloseContextMenu();
        CreateConversation(appState, setAppState, users, appState.messages, props.updateRefs)
      }
    })

    // actions.push({
    //   text: 'Call '+contextedUser?.metadata?.nickname ?? contextedUser?.displayName,
    //   icon: VideoIcon,
    //   onClick: () => console.log('Start Call')
    // })

    // actions.push({
    //   text: 'WIP Flag User',
    //   icon: FlagIcon,
    //   onClick: () => console.log('Flag')
    // })

    // if (IsModerator()) {
    //   actions.push({
    //     text: 'Ban user',
    //     icon: HammerIcon,
    //     onClick: () => console.log('Ban')
    //   })
    // }

    actions.push({
      text: 'Cancel',
      icon: CancelIcon,
      onClick: () => CancelContext()
    })

    return actions;
  }

  const IsModerator = () => appState.userData?.roles.includes('moderator') || appState.userData?.roles.includes('super-moderator')

  const CloseContextMenu = () => {
    setAppState(prev => {
      let asx: IAppState = {...prev};
      asx.userContext = null;
      return asx;
    })
  }

  const CancelContext = () => {
    CloseContextMenu();
  }

  

  return (
    <ContextMenuContainer onClick={() => CancelContext()} active={contextedUser !== null}>
      <ContextMenuWrapper onClick={(e) => e.stopPropagation()} active={contextedUser !== null}>
        <ContextMenuHeaderWrapper>
          {contextedUser?.metadata?.nickname ?? contextedUser?.displayName}
        </ContextMenuHeaderWrapper>
        {IsModerator() && 
          <ContextMenuUserStatsWrapper>
            Stats Block (WIP)<br></br>
            Will show moderation stats, like flag count, if banned before, and any other important metadata (like being an exhibitor)
          </ContextMenuUserStatsWrapper>
        }
        {contextedUser && GetActions().map(action => {
          return (
            <ContextMenuActionWrapper key={action.text} onClick={() => action.onClick()}>
              <ContextMenuActionIconWrapper>
                <ContextMenuActionIconImage src={action.icon} alt='' />
              </ContextMenuActionIconWrapper>
              {action.text}
            </ContextMenuActionWrapper>
          );
        })}
      </ContextMenuWrapper>
    </ContextMenuContainer>
  )
}

export default UserContextMenu;
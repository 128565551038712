import styled, { keyframes } from 'styled-components';
import colors from '../../utils/theme';

import CheckmarkIcon from '../../media/icons/checkmark.svg';
import LoadingImageIcon from '../../media/icons/loading-icon.svg';
import CancelIcon from '../../media/icons/cancel.svg';
import SkipIcon from '../../media/icons/skip.svg';

const SwitchContainer = styled.div`
  height: 32px;
  width: 54px;
  cursor: pointer;
`

const SwitchWrapper = styled.div<{
  disabled?: boolean
}>`
  height: 100%;
  width: 100%;
  border-radius: 16px;
  background: ${p => p.disabled ? colors.gray : colors.black};
  transition: all 222ms;
  display: flex;
  position: relative;
  align-content: center;
  align-items: center;
  pointer-events: none;
`

const SwitchButton = styled.div<{
  isOn: boolean
  disabled?: boolean
  isLoading?: boolean
}>`
  background: ${p => 
    p.disabled ? colors.gray : 
      p.isLoading ? colors.info : 
        p.isOn ? colors.success : colors.danger
  };
  height: 28px;
  width: ${p => p.isLoading ? 'calc(100% - 4px)' : '28px'};
  border-radius: 14px; 
  margin: 0 2px;
  position: absolute;
  transition: all 222ms;
  left: ${p => p.isOn && !p.isLoading ? 'calc(100% - 32px)' : '0' };
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const CancelIconImg = styled.img<{
  isOn: boolean
}>`
  width: 16px;
  height: 16px;
  position: absolute;
  transition: all 222ms;

  opacity: ${p => p.isOn ? '1' : '0'};
  transform: rotateZ(${p => p.isOn ? '0deg' : '45deg'});
  pointer-events: none;
  user-select: none;
`

const LoadingIconSpinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`

const LoadingIconImg = styled.img<{
  isOn: boolean
}>`
  width: 16px;
  height: 16px;
  position: absolute;
  transition: all 222ms;

  opacity: ${p => p.isOn ? '1' : '0'};
  transform: rotateZ(${p => p.isOn ? '0deg' : '45deg'});
  pointer-events: none;
  user-select: none;
  animation-name: ${LoadingIconSpinner};
  animation-iteration-count: infinite;
  animation-timing-function: cubic;
  animation-duration: 555ms;
`

const CheckmarkIconImg = styled.img<{
  isOn: boolean
}>`
  width: 16px;
  height: 16px;
  transition: all 222ms;
  position: absolute;

  opacity: ${p => p.isOn ? '1' : '0'};
  transform: rotateZ(${p => p.isOn ? '0deg' : '-45deg'});
  pointer-events: none;
  user-select: none;

`

const SkipIconImg = styled.img<{
  isOn: boolean
}>`
  width: 22px;
  height: 22px;
  position: absolute;
  transition: all 222ms;

  opacity: ${p => p.isOn ? '1' : '0'};
  transform: rotateZ(${p => p.isOn ? '0deg' : '45deg'});
  pointer-events: none;
  user-select: none;
`

type Props = {
  value: boolean
  disabled?: boolean
  loading?: boolean
  onChange: () => void
}

const SwitchSlider = (props: Props) => {
  let {
    value,
    disabled,
    loading,
    onChange
  } = props;

  return (
    <SwitchContainer onClick={onChange}>
      <SwitchWrapper disabled={disabled}>
        <SwitchButton disabled={disabled} isLoading={loading} isOn={!disabled && !loading && value}>
          <CheckmarkIconImg 
            src={CheckmarkIcon} 
            alt=''
            isOn={!disabled && !loading && value}
          />
          <LoadingIconImg 
            src={LoadingImageIcon} 
            alt=''
            isOn={(!disabled && loading) ?? false}
          />
          <CancelIconImg 
            src={CancelIcon} 
            alt=''
            isOn={!disabled && !loading && !value}
          />
          <SkipIconImg 
            src={SkipIcon} 
            alt=''
            isOn={(disabled && !loading) ?? false}
          />
        </SwitchButton>
      </SwitchWrapper>
    </SwitchContainer>
  )
}

export default SwitchSlider;
import { createQuery, joinChannel, runQuery } from '@amityco/ts-sdk';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import IAppState from '../../@types/IAppState';
import IMessage from '../../@types/IMessage';
import IUserData from '../../@types/IUserData';
import { Amity_GetChannelMembers, Amity_GetMessages, Amity_ModifyUserMetadata, GetSingleAmityUser, JoinChannel } from '../../utils/AmityManager';
import { AmityCallUpdateSignature, GetCurrentRoomName } from '../../utils/ChatUtils';
import { Translate } from '../../utils/i18nManager';
import AppSettings from '../../utils/settings';
import colors from '../../utils/theme';
import MessageBubble from './MessageBubble';
import MessageInput from './MessageInput';

const RoomContainer = styled.div<{
}>`
  position: absolute;
  left: 0; 
  top: 56px;
  right: 0;
  bottom: 0;
  transition: all 333ms;
  overflow: hidden;
  z-index: 10;
  background: ${colors.black};
`

const RoomWrapper = styled.div<{
  inChat: boolean
}>`
  height: calc(100vh - 126px);
  border-top: 1px solid ${colors.gray}88;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  transition: all ${p => p.inChat ? '222ms' : '555ms'};
  transform: translateX(${p => p.inChat ? '0%' : '80%'});
  background: ${colors.black};
`

const SystemMessage = styled.div`
  width: 100%;
  text-align: center;
  color: ${colors.gray}88;
  margin: 0.5rem 0 1rem 0;
`

const VideoRequestWrapper = styled.div`
  z-index: 50;
  margin: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 1rem;
  color: ${colors.white};
  font-size: 1.25rem;
  font-weight: 500;
  background: ${colors.black};
  border-radius: 5rem;
  box-shadow: 0 0 4px ${colors.black}88;
`

type Props = {
  appState: IAppState
  setAppState: Dispatch<SetStateAction<IAppState>>
  updateRefs: (ref: 'users' | 'channels' | 'messages', newValue: any, type: 'combine' | 'overwrite', forceUpdate: boolean) => void
}

type IMessageBubble = Amity.Message & {
  author: boolean
  joint: boolean
  prejoint: boolean
}

const RoomView = (props: Props) => {

  let {
    appState,
    setAppState
  } = props;

  const [messages, setMessages] = useState<IMessageBubble[]>([]);
  const scrollWaitRef = useRef<NodeJS.Timeout | null>(null);
  let callActive = useRef<boolean>(false);
  
  const roomWrapper = useRef(null);
  const [shortRoom, setShortRoom] = useState<boolean>(false);

  const updateTimer = useRef<NodeJS.Timeout | null>(null);
  const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);

  // Construct Messages
  useEffect(() => {
    let msgs: IMessageBubble[] = []
    let lastMessage: IMessageBubble | null = null;
    appState.messages
    .filter(msg => msg.channelId === appState.currently.inRoom )
    .sort((a: Amity.Message, b: Amity.Message) => new Date(a.createdAt as string).getTime() - new Date(b.createdAt as string).getTime())
    .forEach((msg, i) => {
      let joint = false;
      if(lastMessage) {
        if(new Date(msg.createdAt as string).getTime() - new Date(lastMessage?.createdAt as string).getTime() < AppSettings.jointMessageDelay && lastMessage.userId === msg.userId) {
          joint = true;
          msgs[i-1].prejoint = true;
        }
      }

      let msx: IMessageBubble = {
        ...msg,
        joint: joint,
        prejoint: false,
        author: msg.userId === appState.userData!.userId
      }
      lastMessage = msx;
      msgs.push(msx);
    })
    setMessages(msgs);
    if (isAtBottom) {
      ScrollToBottom();
    }

    if (roomWrapper.current) {
      let el: HTMLDivElement = roomWrapper.current as HTMLDivElement;
      el.addEventListener('scroll', UpdateScrollEnd)
    }

    return () => {
      if (roomWrapper.current) {
        let el: HTMLDivElement = roomWrapper.current as HTMLDivElement;
        el.addEventListener('scroll', UpdateScrollEnd)
      }
    }
  }, [appState]);

  // Updates The "x seconds ago text"
  useEffect(() => {
    updateTimer.current = setInterval(() => {
      setNeedsUpdate(true);
    }, 1000); 

    return () => {
      updateTimer.current = null
    }
  },[])

  useEffect(() => {
    setNeedsUpdate(false);
  }, [needsUpdate])

  // useEffect(() => {
  //   // Checking Active Call
  //   console.log('Checking rooms', appState.activeCalls);
  //   callActive.current = appState.activeCalls.includes(appState.currently.inRoom!);
  // }, [appState.activeCalls])

  // Join Room on Entry
  useEffect(() => {
    let channel: string = appState.currently.inRoom as string;
    if(channel) {
      // console.log('Joining Channel', channel);
      JoinChannel(channel);
      // let newMeta = {...appState.userData!.metadata} 
      // newMeta['inCall'] = false;
      // Amity_ModifyUserMetadata(appState.userData!.userId, newMeta);
      Amity_GetChannelMembers(channel).then(async members => {
        // console.log('Members', members);
        // console.log('Users', appState.users);
        // get all possible new user information
        // iterate and check if they already exist
        // add new
        let memberUsers: any[] = []
        for (let mbx in members) {
          let member = members[mbx];
          let tUser = await GetSingleAmityUser(member.userId);
          memberUsers.push(tUser);
        }
        // Iterate through users in the member list.
        memberUsers.forEach((user, i) => {
          let included = false;
          appState.users.forEach(cUser => {
            if (cUser.userId === user.userId) {
              included = true;
            }

          })
          // Remove if already in array.
          if (included) {
            memberUsers.splice(i);
          }
        })
       
        // Update Refs
        props.updateRefs("users", memberUsers, "combine", true);

      })

    }
  }, [appState.currently.inRoom])

  // Scroll to bottom of message list.
  useEffect(() => {
    scrollWaitRef.current = setTimeout(() => {
      ScrollToBottom()
    }, 1); // Only works while in a timeout for some reason? :|
    return () => {
      scrollWaitRef.current = null;
    }
  }, [appState.loading]);

  // Determine if message input should be forced to the bottom or not.
  // Returns true if the room lacks enough messages to require a scrollbar.
  useEffect(() => {
    if(roomWrapper.current) {
      let el: HTMLDivElement = roomWrapper.current as HTMLDivElement;
      setShortRoom(el.clientHeight <= el.scrollHeight);
    }
  }, [appState.currently.scrollingToBottom])

  const ScrollToBottom = () => {
    if(roomWrapper.current) {
      let el: HTMLDivElement = roomWrapper.current as HTMLDivElement;
      el.scrollTo({ top: el.scrollHeight, behavior: 'auto'});
    }
  }

  const OpenContextMenu = (userId: string) => {
    for (let usx of appState.users) {
      if (usx.userId === userId) {
        setAppState(prev => {
          let asx: IAppState = {...prev}
          asx.userContext = usx;
          return asx;
        })
      }
    }
  }

  const GetUserName = (userId: string): string => {
    for (let usx of appState.users) {
      if (usx.userId === userId) {
        return usx.metadata?.nickname ?? usx.displayName ?? 'Anonymous';
      }
    }
    return 'Anonymous'
  }

  const UpdateScrollEnd = () => {
    if (roomWrapper.current) {
      let el: HTMLDivElement = roomWrapper.current;
      setIsAtBottom((el.scrollTop + el.clientHeight >= el.scrollHeight));
    }
  }

  return (
    <RoomContainer>
      {/* <VideoRequestWrapper>
        Call Request
      </VideoRequestWrapper> */}
      <RoomWrapper className='room-wrapper' inChat={appState.currently.inRoom !== null} ref={roomWrapper}>
        <SystemMessage>{Translate('end-of-history', appState.language)}</SystemMessage>
        {messages.map((msg, i) => {
          return (
            <MessageBubble
              key={i}
              message={msg}
              author={msg.author}
              joint={msg.joint}
              userName={GetUserName(msg.userId)}
              prejoint={msg.prejoint}
              openContextMenu={OpenContextMenu}
            />
          )
        })}
      </RoomWrapper>
      <MessageInput language={appState.language} channelId={appState.currently.inRoom as string} />
    </RoomContainer>
  )
}

export default RoomView;
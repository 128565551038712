import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from '../../utils/theme';

import SendIcon from '../../media/icons/sendFilled.svg';
import AppSettings from '../../utils/settings';
import { createMessage, createQuery, runQuery } from '@amityco/ts-sdk';
import { Amity_SendMessage } from '../../utils/AmityManager';
import { Translate } from '../../utils/i18nManager';

const InputContainer = styled.div`
  width: calc(100%);
  margin: 0;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-top: 1px solid ${colors.gray}88;
`

const InputWrapper = styled.div<{
  rows: number
}>`
  position: relative;
  width: 100%;
  transition: all 111ms;
  min-height: ${p => `calc((18px * ${p.rows}) + 32px)` };
  display: flex;
  justify-content: flex-end;
  align-content: stretch;
  align-items: stretch;
  border-radius: 8px 8px 0 0;
  margin-bottom: 0;
  background: ${colors.black};
`

const Input = styled.textarea<{
  color: string
}>`
  position: absolute;
  top: 0;
  left: 0; 
  right: 0;
  bottom: 0;
  border-radius: 0.25rem;
  width: calc(100% - 6rem);
  outline: none;
  border: none;
  margin: 0.8rem;
  padding: 0.5rem;
  background: transparent;
  transition: all 222ms;
  color: ${p => p.color};
  font-family: Sans-Serif;
  font-size: 1rem;
  border-bottom: 1px solid ${p => p.color}88;
  &::placeholder {
    color: ${p => p.color}88;
  }
`

const SendIconWrapper = styled.div`
  position: relative;
  width: 32px;
  border-radius: 8px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0.5rem;
  cursor: pointer;
  transition: all 222ms;
  &:hover {
    background: ${colors.gray}33;
    box-shadow: 0 0 4px ${colors.gray}33;
  }
`

const SendIconImg = styled.img`
  width: 100%;
  height: 100%;
  filter: invert(100%);
`

type Props = {
  channelId: string,
  language: string,
}

const MessageInput = (props: Props) => {

  const [composed, setComposed] = useState<string>('');
  const [inputRows, setInputRows] = useState<number>(1);

  // Input controls
  const [shiftPressed, setShiftPressed] = useState<boolean>(false);
  const [enterPressed, setEnterPressed] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const [inputColor, setInputColor] = useState<string>(colors.white);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if(!shiftPressed && enterPressed) {
      SendMessage();
    }
  })

  useEffect(() => {
    inputRef.current?.focus();
  }, [props.channelId])

  const SendMessage = async () => {
    if(composed && composed.trim() !== '') {
      // Reset controls.
      setShiftPressed(false);
      setEnterPressed(false);
      setIsSending(true);

      // Handle Send
      await Amity_SendMessage(props.channelId, composed).catch(() => {
        FlashInput(colors.danger)
        setIsSending(false);
      })

      // Reset message.
      setIsSending(false);
      setComposed('');
      setInputRows(0);
      inputRef.current?.focus();
    } else {
      
    }

  }

  const FlashInput = (color: string) => {
    setInputColor(color)
    setTimeout(() => {
      setInputColor(colors.white);
    }, 1000);
  }

  const HandleInputChange = (e: any) => {
    let lines = e.target.value.split('\n').length + 1;
    setComposed(e.target.value);
    setInputRows(lines <= AppSettings.maxMessageInputLines ? lines : AppSettings.maxMessageInputLines);
  }

  const HandleKeyDown = (e: any) => {
    if(e.code === 'ShiftLeft' || e.code === "ShiftRight") 
      setShiftPressed(true);

    if(e.code === "Enter" || e.code === "NumpadEnter")
      setEnterPressed(true);

  }

  const HandleKeyUp = (e: any) => {
    if(e.code === 'ShiftLeft' || e.code === "ShiftRight") 
      setShiftPressed(false);

    if(e.code === "Enter" || e.code === "NumpadEnter")
      setEnterPressed(false);

  }

  return (
    <InputContainer>
      <InputWrapper rows={inputRows}>
        <Input 
          ref={inputRef}
          color={inputColor}
          rows={inputRows}
          value={composed}
          disabled={isSending}
          onKeyDown={HandleKeyDown}
          maxLength={1000}
          onKeyUp={HandleKeyUp}
          onChange={HandleInputChange}
          placeholder={Translate('write-message', props.language)}
        />
        <SendIconWrapper onClick={SendMessage}>
          <SendIconImg src={SendIcon} alt='' />
        </SendIconWrapper>
      </InputWrapper>
    </InputContainer>
  )
}

export default MessageInput;
import { darken } from 'polished';
import { useState } from 'react';
import styled from 'styled-components';
import colors from '../../utils/theme';

const InputContainer = styled.div`
  width: calc(100% - 3.5rem);
  height: 48px;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  margin: 1rem;
`

const InputWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
`

const InputLabelWrapper = styled.div<{
  active?: boolean
}>`
  position: absolute;
  transition: all 222ms;
  left: ${p => p.active ? '0' : '0.5rem'};
  top: ${p => p.active ? '-8px' : '1rem'};
  color: ${colors.gray};
  font-size: ${p => p.active ? '0.75rem' : '1rem'};
  z-index: 10;
  font-weight: 500;
  userSelect: none;
`

const Input = styled.input<{
  disabled?: boolean
}>`
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0.5rem;
  border-radius: 0.33rem;
  border: none;
  outline: none;
  transition: all 222ms;
  cursor: ${p => p.disabled ? 'auto' : 'text'};
  box-shadow: inset 0 1px 2px ${colors.black}${p => p.disabled ? '00' : '33'};
  background: ${darken('0.05', colors.white)}${p => p.disabled ? '00' : 'FF' };
  font-size: 1rem;
`

type Props = {
  placeholder: string
  maxLength?: number
  value: string
  autoComplete?: 'no'
  onChange: (e:any) => void
  onBlur?: (e:any) => void
}

const FormInput = (props: Props) => {

  const [inFocus, setInFocus] = useState<boolean>(false);

  const handleChange = (e: any) => {
    props.onChange(e);
  }

  return (
    <InputContainer>
      <InputWrapper>
        <InputLabelWrapper active={props.value !== '' || inFocus}>
          {props.placeholder}
        </InputLabelWrapper>
        <Input  
          maxLength={props.maxLength}
          autoComplete={props.autoComplete}
          onFocus={() => setInFocus(true)}
          onBlur={(e) => {
            setInFocus(false);
            if(props.onBlur) {
              props.onBlur(e)
            };
          }}
          value={props.value} 
          onInput={handleChange} 
        />
      </InputWrapper>
    </InputContainer>
  )
} 

export default FormInput;
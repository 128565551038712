import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import IAppState from '../../@types/IAppState';
import { CreateConversation } from '../../utils/AmityManager';
import { GetUserStatus, GetUserStatusIcon, GetUserStatusText, UserStatus } from '../../utils/ChatUtils';
import { Translate } from '../../utils/i18nManager';
import colors from '../../utils/theme';
import UserSegment from '../chatElements/UserSegment';

const UsersContainer = styled.div<{
  active: boolean
}>`
  width: 100%;
  height: calc(100% - 56px);
  margin-top: 56px;
  position: absolute;
  z-index: 99;
  overflow-x: hidden;
  overflow-y: ${p => p.active ? 'auto' : 'hidden'};
  color: ${colors.white};
  background: ${colors.black}${p => p.active ? 'FF' : '00'};
  pointer-events: ${p => p.active ? 'all' : 'none'};
`

const UsersWrapper = styled.div<{
  active: boolean
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; 
  bottom: 0;
  transition: all 222ms;
  left: ${p => p.active ? '0px' : '100%'};
  right: ${p => p.active ? '0px' : '-100%'};
`

const SearchWrapper = styled.div`
  width: calc(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`

const SearchInput = styled.input`
  width: calc(100% - 3rem);
  margin: 1rem;
  padding: 0.5rem;
  font-size: 16px;
  background: ${colors.deep}33;
  color: ${colors.white};
  border: none;
  outline: none;

  &:placeholder {
    color: ${colors.gray};
  }
`

type Props = {
  appState: IAppState
  setAppState: Dispatch<SetStateAction<IAppState>>
  updateRefs: (ref: 'users' | 'channels' | 'messages', newValue: any, type: 'combine' | 'overwrite', forceUpdate: boolean) => void
}

const UsersPanel = (props: Props) => {
  let { appState, setAppState } = props;

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const GetUsers = (): Amity.User[] => {
    let { banterMode, users } = appState;
    // Exclude Self
    users = users.filter(user => user.userId !== appState.userData?.userId);
    // Only include other users in this show.
    users = users.filter(user => user.metadata?.show === appState.show);
    // ExhibitorOutreach Mode | Exclude Other Exhibitors
    if (banterMode === 'exhibitorOutreach') {
      users = users.filter(user => user.metadata?.exhibitor === undefined);
    }
    return users;
  }

  const HandleUserClick = (user: Amity.User) => {
    let users: Amity.User[] = []
    users.push(user);
    CreateConversation(appState, setAppState, users, appState.messages, props.updateRefs);
  }

  const OpenContextMenu = (user: Amity.User) => setAppState(prev => {
    let asx: IAppState = {...prev}
    asx.userContext = user;
    return asx;
  })

  return (
    <UsersContainer active={(appState.viewPanel === 'users')}>
      <UsersWrapper active={(appState.viewPanel === 'users')}>
        <SearchWrapper>
          <SearchInput placeholder={Translate('search', appState.language)} value={searchInputValue} onChange={(e) => setSearchInputValue(e.target.value) }/>
        </SearchWrapper>
        {GetUsers()
          .sort((a, b) => {
            let aName: string = a.metadata?.nickname ?? a.displayName;
            let bName: string = b.metadata?.nickname ?? b.displayName;
            return aName.localeCompare(bName);
          })
          .filter(user => {
            if (searchInputValue === '') return true;
            if (user.displayName?.toLowerCase().includes(searchInputValue.toLowerCase())) return true;
            if (user.metadata?.nickname?.toLowerCase().includes(searchInputValue.toLowerCase())) return true;
            return false;
          })
          .map((user, i) => {
            return (
              <UserSegment key={i} user={user} onSegmentClick={() => HandleUserClick(user)} onOpenContextMenu={() => OpenContextMenu(user)} />
            )
        })}
      </UsersWrapper>
    </UsersContainer>
  )
}

export default UsersPanel;
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import colors from '../utils/theme';
import notificationSound from '../media/sounds/notification_alt.mp3';
import incomingCallSound from '../media/sounds/callRingtone.mp3';

import SideNav from './panels/SideNav';
import TopNav from './TopNav';

import IAppState from '../@types/IAppState';
import LobbyView from './chatElements/LobbyView';
import RoomView from './chatElements/RoomView';
import ErrorMessage from './panels/ErrorMessage';
import ChatSettingsNav from './ChatSettingsNav';
import { 
  createClient, 
  connectClient, 
  onChannelUpdated,
  onMessageUpdated,
  onMessageCreated,
  onMessageDeleted,
  onUserUpdated,
  onChannelJoined,
  onChannelDeleted,
} from '@amityco/ts-sdk';
import { useSound } from 'use-sound';
import { 
  Amity_GetChannels, 
  Amity_GetMessages, 
  Amity_ModifyUserMetadata, 
  CheckInChannel, 
  CreateConversation,
  Amity_GetAllUsers
} from '../utils/AmityManager';
import ConnectingMessage from './panels/ConnectingMessage';
import { DetectLanguage, Translate } from '../utils/i18nManager';
import LoadingPanel from './panels/LoadingPanel';
import jwtDecode from 'jwt-decode';
import { SendCommand } from '../utils/CommandManager';
import UsersPanel from './panels/UsersPane';
import UserContextMenu from './panels/UserContextMenu';
import { AmityCallUpdateSignature, GetUserStatus, SendNotifications } from '../utils/ChatUtils';
import ARoomCall from './panels/ARoomCall';
import { CheckRoomState } from '../utils/ARoomIntegration';

const BanterContainer = styled.div<{
  height: number
  width: number
}>`
  // width: ${p => p.width}px;
  // height: ${p => p.height}px;
  font-family: 'ProximaNovaRegular';
`

const BanterWrapper = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  width: 100%;
  height: 100%;
`

const App = () => {

  const AMITY_API_URL = 'https://api.us.amity.co';
  // const AMITY_API_Key = 'b0ede05a3b8ef46145318f15060c13dd835f89b1ba316d2c'; // v1 Key
  const AMITY_API_Key = 'b0edbc5d388ea6624b32841f045e1688d80189b4e832682d'; // v2 Key

  const statusDelay = 15 * 60 * 1000; // minutes * 60000 = milliseconds for delay;
  const switchRef = useRef<NodeJS.Timeout | null>(null); // Used for smoothing the switch between channels.

  const amityUsers = useRef<Amity.User[]>([]);
  const amityChannels = useRef<Amity.Channel[]>([]);
  const amityMessages = useRef<Amity.Message[]>([]);
  const aroomActiveCalls = useRef<string[]>([]);
  const myUser = useRef<Amity.User | null>();

  const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);

  // User Status
  const userStatusTimerRef = useRef<NodeJS.Timeout | null>(null);
  const userStatusLastCheck = useRef<string>(new Date().toUTCString());

  // Sounds
  const notificationSoundEffect = useRef<HTMLAudioElement>(new Audio(notificationSound) as HTMLAudioElement);
  const soundOn = useRef<boolean>(false);
  const showId = useRef<string>('');

  const [amity, setAmity] = useState<Amity.Client | null>(createClient(AMITY_API_Key, AMITY_API_URL));
  const [appState, setAppState] = useState<IAppState>({
    connecting: true,
    loading: false,
    language: DetectLanguage(),
    isMobile: window.outerWidth <= 600,
    viewPanel: null,
    show: null,
    chatToken: null,
    banterMode: null,
    userContext: null,
    igniteDomain: null,
    igniteToken: null,
    amity: {
      connected: false,
    },
    userData: null,
    currently: {
      inRoom: null,
      switching: null,
      leaving: false,
      inCall: null,
      scrollingToBottom: false,
      editingRoom: false,
    },
    activeCalls: [],
    messages: [],
    channels: [],
    checkins: {},
    favorites: [],
    error: null,
    users: [],
    settings: {
      nickname: null,
      soundOn: true,
      soundVolume: 0.5,
    }
  });

  useEffect(() => {
    // Check if leaving room.
    let { currently } = appState;
    if(currently?.leaving) {

      // Update leaving.
      let switchDelay = currently.inRoom !== null && currently.switching !== null ? 333 : 0;
      setAppState(prev => {
        let asx: IAppState = {...prev}
        asx.currently.leaving = false;
        asx.currently.inRoom = null;
        return asx;
      })

      // Update state again after animation to nullify or replace current room.
      switchRef.current = setTimeout(() => {
        setAppState(prev => {
          let asx: IAppState = {...prev}
          asx.currently.inRoom = asx.currently.switching;
          asx.currently.switching = null;
          return asx;
        })
      }, switchDelay);
    }

    soundOn.current = appState.settings.soundOn;

    return () => {
      switchRef.current = null;
    }
  })

  

  useLayoutEffect(() => {
    // Add resize listener
    let updateWindowSize = () => {
      setAppState(prev => {
        let asx: IAppState = {...prev}
        asx.isMobile = window.outerWidth <= 600;
        return asx;
      })
    }
    window.addEventListener('resize', updateWindowSize)
    return () => window.removeEventListener('resize', updateWindowSize);
  }, [])

  /**
   * Force Update of current refs.
   */
  useEffect(() => {
    if (needsUpdate) {
      setAppState(prev => {
        let asx: IAppState = {...prev};

        let userData: Amity.User | null = asx.userData;
        for (let usx of amityUsers.current) {
          if (usx.userId === userData?.userId) {
            userData = usx;
          } 
        }

        asx.activeCalls = aroomActiveCalls.current;
        asx.messages = amityMessages.current;
        asx.channels = amityChannels.current;
        asx.users = amityUsers.current;
        asx.userData = myUser.current ?? null;
        asx.settings = {
          nickname:  myUser.current?.metadata?.nickname ?? null,
          soundOn:  myUser.current?.metadata?.soundOn ?? true,
          soundVolume:  myUser.current?.metadata?.soundVolume ?? 0.5,
        }
        SendNotifications(asx);
        return asx;
      })
      setNeedsUpdate(false);
    }
  }, [needsUpdate])

  useEffect(() => {
    let checkTimer = setInterval(async () => {
      let calls = [...aroomActiveCalls.current];
      for(let chx of amityChannels.current) {
        let status = await CheckRoomState(chx.channelId)
        // console.log('Checking Status of '+chx.channelId, status);
        if (status) {
          // Add to active rooms
          if (!calls.includes(chx.channelId)) {
            calls.push(chx.channelId);
          }
        } else {
          // Remove from active rooms
          // console.log('Removing from room.');
          if (calls.includes(chx.channelId)) {
            calls = calls.filter(c => c !== chx.channelId);
          }
        }
      }
      aroomActiveCalls.current = calls;
      setNeedsUpdate(true);
      if (calls.length > 0) {
        SendCommand('callActive', true);
      } else {
        SendCommand('callActive', false);
      }
      // console.log('Updated Rooms', calls);
    }, 10000);

    return () => {
      clearInterval(checkTimer);
    }
  }, [])

  useEffect(() => {
    userStatusTimerRef.current = setInterval(() => {
      if (myUser.current?.userId) {
        let newMeta = {...myUser.current?.metadata}
        newMeta.lastActivity = userStatusLastCheck.current;
        Amity_ModifyUserMetadata(myUser.current?.userId, newMeta).then(res => {
          if (res.userId === myUser.current?.userId) {
            myUser.current = res;
            setNeedsUpdate(true);
          }
        })
      }
    }, 1000 * 15); // Every 15 seconds.

    window.addEventListener('click', () => userStatusLastCheck.current = new Date().toUTCString());
    window.addEventListener('scroll', () => userStatusLastCheck.current = new Date().toUTCString());
    window.addEventListener('mouseenter', () => userStatusLastCheck.current = new Date().toUTCString());
    window.addEventListener('keypress', () => userStatusLastCheck.current = new Date().toUTCString());

    return () => {
      userStatusTimerRef.current = null;
    }
  }, [])

  useEffect(() => {
    // Create Message Handler
    console.log('Starting Event Listener')
    window.addEventListener('message', message => {
      let data = message.data;
      // console.log('Message Received:', data);
      switch(data.command) {
        /**
         * Promotes a user to exhibitor.
         * // FIXME this is an incredibly insecure method. Literally anyone can submit a userId and get promoted.
         */
        case 'promoteToExhibitor': 
          let userData = data.value;
          if (
            myUser.current &&
            userData.userId && 
            userData.userId === myUser.current?.userId && 
            userData.boothSnippetId
          ) {
            let newMeta = myUser.current.metadata;
            if (newMeta) {
              newMeta.exhibitor = userData.boothSnippetId
              console.log('Promoting To Exhibitor', userData.userId, newMeta);
              Amity_ModifyUserMetadata(userData.userId, newMeta);
            }
          }
        break;
        /**
         * Responds to requester with an array of users that are online from the array of the requested userIds.
         */
        case 'confirmRepsOnline': 
          console.log('Confirming Reps!');
          let repsList: string[] = data.value as string[];
          let onlineReps: string[] = [];
          console.log('confirmRepsOnline', repsList)
          // if (appState.banterMode !== 'exhibitorOutreach' || myUser.current?.metadata?.exhibitor) {
          //   console.log('Forcing no reps online because you are an exhibitor.');
          //   SendCommand('responseRepsOnline', []);
          // }
          for (let usx of amityUsers.current) {
            if (!repsList.includes(usx.userId)) continue;
            console.log('Rep Online:', usx);
            let status = GetUserStatus(usx);
            if (status === 'available' || status === 'idle') {
              onlineReps.push(usx.userId);
            }
          }
          console.log('Online reps:', onlineReps);
          SendCommand('responseRepsOnline', onlineReps);
        break;
        /**
         * Checks if users are online.
         */
        case 'confirmUsersOnline':
          // console.log('Confirming Users Online', data.value);
          let checkUsers: string[] = data.value as string[];
          let onlineUsers: string[] = []
          for (let usx of amityUsers.current) {
            if (checkUsers.includes(usx.userId)) {
              let status = GetUserStatus(usx);
              if (status === 'available' || status === 'idle') {
                onlineUsers.push(usx.userId);
              }
            }
          }
          // console.log('Sending Online Users', onlineUsers);
          SendCommand('responseUsersOnline', onlineUsers);
        break;
        /**
         * Initiates a channel between all of the supplied users.
         */
        case 'initializeRepresentativeChat': 
          console.log('initializeRepresentativeChat', data.value);
          let userIds: string[] = data.value;
          let users: Amity.User[] = []
          for (let usx of amityUsers.current) {
            if (userIds.includes(usx.userId)) {
              users.push(usx);
            }
          }
          CreateConversation(appState, setAppState, users, amityMessages.current, UpdateRefs);
        break;
      }
    })

    // Initialize Amity's Client
    InitializeAmity(appState);

    /**
     * Channel Handlers
     */
    onChannelJoined(channel =>  {
      console.log('channel joined');
    })

    onChannelDeleted(channel => {
      let channels = amityChannels.current;
      for (let i = 0; i < channels.length; i++) {
        if (channel.channelId === channels[i].channelId) {
          console.warn(channel.displayName, ' was deleted.');
          channels.splice(i, 1);
        }
      }
      setNeedsUpdate(true);
    })

    onChannelUpdated(async channel => {
      console.log(channel);
      let channels = [...amityChannels.current];
      for(let chx of channels) {
        if (chx.channelId === channel.channelId) {
          chx = channel;
        }
      }
      amityChannels.current = channels;
      console.log(channel);
      // amityUsers.current = Amity_GetAllUsers();
      setNeedsUpdate(true);
    })

    /**
     * Message Handlers
     */
    onMessageCreated(message => {
      let channelExists = false;
      for(let chx of amityChannels.current) {
        if (chx.channelId === message.channelId) {
          channelExists = true;
        }
      }
      if (!channelExists) {
        Amity_GetChannels(showId.current, {limit: 1000, after: 0}).then(res => {
          amityChannels.current = res;
        })
      }
      // Automatically update checkin
      if (message.channelId === appState.currently.inRoom && myUser.current) {
        CheckInChannel(message.channelId, myUser.current);
      }

      // Play message sound if not in room
      if (message.channelId !== appState.currently.inRoom && soundOn.current) {
        notificationSoundEffect.current.volume = 0.5;
        notificationSoundEffect.current.play();
      }

      // Add message to array.
      amityMessages.current.push(message);
      amityMessages.current = amityMessages.current.filter(msg => msg.data.text !== AmityCallUpdateSignature)
      if (message.data.text === AmityCallUpdateSignature) {
        console.log('Someones joining a call');
      }
      setNeedsUpdate(true);
    })

    onMessageUpdated(message => {
      let messages = [...amityMessages.current];
      for (let msx of messages) {
        if (msx.messageId === message.messageId) {
          msx = message;
        }
      }
      amityMessages.current = messages;
      setNeedsUpdate(true);
    })

    onMessageDeleted(message => {
      let messages = [...amityMessages.current];
      for (let i = 0; i < messages.length; i++) {
        let msx = messages[i];
        if (msx.messageId === message.messageId) {
          messages.splice(i, 1);
        }

      }
      amityMessages.current = messages.filter(msg => msg.data.text !== AmityCallUpdateSignature);
      setNeedsUpdate(true);
    })

    /**
     * User Handlers
     */
    onUserUpdated(user => {
      let users = [...amityUsers.current];
      for (let i = 0; i < users.length; i++) {
        if (users[i].userId === user.userId) {
          users[i] = user;
        }
  
        if (myUser.current?.userId === user.userId) {
          myUser.current = user;
        } 
      }
      amityUsers.current = users;
      setNeedsUpdate(true);
    })

    /**
     * Caching
     */
    // enableCache();

    return () => {
      amityUsers.current = [];
    }

  }, [])

  const InitializeAmity = (appState: IAppState) => {
    let params = Object.fromEntries(new URLSearchParams(window.location.href.split('?')[1]))
    let tokenData: any = null;
    if (params.token) {
      tokenData = jwtDecode(params.token);
    }

    // Make sure token has the required fields.
    if (!tokenData || !tokenData.aud || !tokenData.banterMode) {
      setAppState(prev => {
        let asx: IAppState = {...prev};
        asx.error = Translate('token-error', appState.language);
        return asx;
      })
      return;
    }

    connectClient({
      userId: tokenData.sub,
      displayName: tokenData.displayName,
    }).then(async (res) => {
      if (res) {

        // Get Amity Channels
        let channels = await Amity_GetChannels(tokenData.aud, {after: 0, limit: 1000});
        amityChannels.current = channels;

        let users: Amity.User[] = await Amity_GetAllUsers();
        // await new Promise<void>(async (resolve, reject) => {
        //   let page = 0;
        //   const GetNext = async (page: number) => {
        //     let tUsers = await Amity_GetUsers({after: page * 1000, limit: 1000});
        //     for (let usx of tUsers) {
        //       users.push(usx);
        //     }
        //     if (tUsers.length === 1000) {
        //       page++;
        //       await GetNext(page);
        //     } else {
        //       resolve();
        //     }
        //   }
        //   GetNext(page);
        // });
        amityUsers.current = users;

        // Get Amity Messages
        let messages: Amity.Message[] = [];
        for(let chx of channels) {
          Amity_GetMessages(chx.channelId, {limit: 1000, after: 0}).then((channelMessages) => {
            messages.push(...channelMessages)
          }).catch((err) => {

          }).finally(() => {
            amityMessages.current = messages.filter(msg => msg.data.text !== AmityCallUpdateSignature);
            // console.log(amityMessages.current);
            setNeedsUpdate(true);
          })
        }
        
        // Get Amity Users
        let userData: Amity.User | null = null;
        for (let usx of users) {
          if (usx.userId === tokenData.sub) {
            // Set the show ID and get the modified userData.
            userData = await Amity_ModifyUserMetadata(tokenData.sub, {
              ...usx.metadata,
              show: tokenData.aud
            });
          } 
        }

        // Get My Amity User
        if (!userData) {
          return setAppState(prev => {
            let asx: IAppState = {...prev};
            asx.error = Translate('user-error', appState.language);
            return asx;
          })
        }
        myUser.current = userData;

        // Get Chat Token
        let chatTokenData: any = await new Promise((resolve, reject) => {
          // console.log('Token Data', tokenData)
          let chatTokenReq = new XMLHttpRequest();
          chatTokenReq.open('POST', 'https://prod-eastus-ingress.inferno.jolokia.com/api/token/chatToken')
          chatTokenReq.setRequestHeader('X-InfernoCore-Domain', tokenData.domain)
          chatTokenReq.setRequestHeader('Authorization', 'Bearer '+tokenData.igniteToken)
          chatTokenReq.setRequestHeader('Content-Type', 'application/json');
          chatTokenReq.addEventListener('load', res => {
            if (chatTokenReq.status === 200) {
              resolve(JSON.parse(chatTokenReq.responseText));
            } else {
              reject(null)
            }
          })
          chatTokenReq.send(JSON.stringify({
            events: [{
              id: tokenData.chatEventId, 
              name: tokenData.displayName
            }]
          }))
        }).catch((err) => {
          console.error(err)
        })

        // if (!chatTokenData) {
        //   setAppState(prev => {
        //     let asx: IAppState = {...prev}
        //     asx.error = 'Token Error'
        //     return asx;
        //   })
        // }

        showId.current = tokenData.aud;

        setAppState(prev => {
          let asx: IAppState = {...prev};
          asx.amity.connected = res;
          asx.channels = amityChannels.current;
          asx.messages = amityMessages.current;
          asx.users = amityUsers.current;
          asx.userData = userData;
          asx.connecting = false;
          asx.show = tokenData.aud;
          asx.igniteDomain = tokenData.domain;
          asx.igniteToken = tokenData.igniteToken;
          asx.chatToken = chatTokenData?.accessToken as string;
          asx.banterMode = tokenData.banterMode;
          asx.settings = {
            nickname: userData?.metadata?.nickname ?? null,
            soundOn: userData?.metadata?.soundOn ?? true,
            soundVolume: userData?.metadata?.soundVolume ?? 0.5,
          }
          return asx;
        })
        SendCommand('connected', null);
      }
    });
  }

  const UpdateRefs = (ref: 'users' | 'channels' | 'messages', newValue: any, type: 'combine' | 'overwrite', forceUpdate: boolean) => {
    switch(ref) {
      case 'users': 
        if (type === 'combine') amityUsers.current = [...amityUsers.current, ...newValue];
        if (type === 'overwrite') amityUsers.current = newValue; 
      break;
      case 'channels': 
        if (type === 'combine') amityChannels.current = [...amityChannels.current, ...newValue];
        if (type === 'overwrite') amityChannels.current = newValue; 
      break;
      case 'messages': 
        if (type === 'combine') amityMessages.current = [...amityMessages.current, ...newValue]; 
        if (type === 'overwrite') amityMessages.current = newValue; 
      break;
    }
    if(forceUpdate) {
      setNeedsUpdate(true);
    }
  }

  return (
    <>
      <ErrorMessage appState={appState} setAppState={setAppState} /> 
      <LoadingPanel appState={appState} />
      <ConnectingMessage appState={appState} setAppState={setAppState} />
      <ARoomCall appState={appState} setAppState={setAppState} />
      <BanterContainer width={window.outerWidth} height={window.outerHeight} >
        <SideNav appState={appState} setAppState={setAppState} />
        <UsersPanel appState={appState} setAppState={setAppState} updateRefs={UpdateRefs} />
        <ChatSettingsNav appState={appState} setAppState={setAppState} />
        <UserContextMenu appState={appState} setAppState={setAppState} updateRefs={UpdateRefs} />
        <BanterWrapper>
          <TopNav appState={appState} setAppState={setAppState} />
          <LobbyView appState={appState} setAppState={setAppState} />
          <RoomView appState={appState} setAppState={setAppState} updateRefs={UpdateRefs} /> 
        </BanterWrapper>
      </BanterContainer>
    </>
  );
}

export default App;

import { Amity_GetChannels } from "./AmityManager";

export interface ARoomPMR {
  token: string
  issuer: 'ignitex' | 'banter'
  domain: string
  name: string
  roomId: string
}

const baseUrl = 'https://sr3l0kj5ik.execute-api.us-west-2.amazonaws.com/rest';

const GetRoom = async (id: string) => await fetch(baseUrl+'/rooms/'+id);

const GetJoinToken = async (data: ARoomPMR) => await fetch(baseUrl+'/rooms/token', {
  method: 'POST', 
  body: JSON.stringify({
    token: data.token,
    issuer: data.issuer,
    personalRoomId: data.roomId,
  })
}).then(res => {
  if (res.status === 200) {
    return res.json()
  } else {
    throw new Error(res.status.toString());
  }
}).catch(err => {
  console.error(err);
})

const CreateRoom = async (data: ARoomPMR) => await fetch(baseUrl+'/rooms', {
  method: 'POST',
  body: JSON.stringify(data)
}).then(res => {
  if (res.status === 200) {
    return res.json()
  } else {
    throw new Error(res.status.toString());
  }
});

/**
 * 
 * @param data 
 * @returns joinToken
 */
export const JoinARoom = async (data: ARoomPMR) => new Promise<any>(async (resolve, reject) => {

  /* GET JOIN TOKEN */
  let room = await GetRoom(data.roomId);
  if (room.status === 200) {
    resolve(await GetJoinToken(data).catch(err => reject(err)));
  } else if (room.status === 404) {
    await CreateRoom(data).catch(err => reject(err));
    let { joinToken } = await GetJoinToken(data).catch(err => reject(err));
    if (joinToken) {
      resolve(joinToken) 
    } else {
      reject('Token error');
    }
  } else {
    reject('There was an error getting room data.');
  }

})

export const UpdateChannelARoom = async (channels: Amity.Channel[]) => new Promise<void>(async (resolve, reject) => {

  for (let channel of channels) {
    let roomId = 'banter-'+channel.channelId;
    let meetingData = await fetch(baseUrl+'/meetings/'+roomId);
    console.log(meetingData);
  }

})

export const CheckRoomState = async (channelId: string) => new Promise<boolean>(async (resolve, reject) => {
  let data = await fetch('https://sr3l0kj5ik.execute-api.us-west-2.amazonaws.com/rest/meetings/banter-'+channelId).then(res => res.json());
  if (data.status === 'Empty') {
    resolve(false)
  } else {
    resolve(true)
  }
})
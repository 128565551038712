import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import colors from '../utils/theme';

import RiveMenuIcon from '../media/rives/MenuArrowBool.riv';
import RiveMagnifyingGlass from '../media/rives/SearchRive.riv';

import CogIcon from '../media/icons/cog.svg';
import MenuIconSvg from '../media/icons/hamburger-menu.svg';
import LeftArrowSvg from '../media/icons/arrow-left.svg';
import UsersIcon from '../media/icons/users.svg';
import InboxIcon from '../media/icons/inbox.svg';
import PopoutIcon from '../media/icons/maximize.svg';
import PopinIcon from '../media/icons/minimize.svg';
import CloseIcon from '../media/icons/close.svg';
import VideoIcon from '../media/icons/video.svg';

import IAppState from '../@types/IAppState';
import { useRive, useStateMachineInput } from 'rive-react';
import { AmityCallUpdateSignature, GetCurrentRoomName } from '../utils/ChatUtils';
import { Translate } from '../utils/i18nManager';
import { SendCommand } from '../utils/CommandManager';
import { ARoomPMR, JoinARoom } from '../utils/ARoomIntegration';
import { Amity_ModifyUserMetadata, Amity_SendMessage } from '../utils/AmityManager';

const TopNavContainer = styled.div<{
  showShadow?: boolean
}>`
  width: 100%;
  height: 55px;
  background: ${colors.black};
  transition: all 222ms;
  box-shadow: 0 0 12px ${colors.black}${p => p.showShadow ? '88' : '00'};
  border-bottom: 1px solid ${colors.white};
`

const TopNavWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`

const MenuButtonWrapper = styled.div`
  display: flex;
  margin-right: 0.5rem;
  justify-content: flex-end;
`

const MenuButton = styled.img<{
  active: boolean
  inverted: boolean
}>`
  position: relative;
  width: 24px;
  height: 24px;
  padding: 0.5rem;
  margin-right: 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  object-fit: contain;
  filter: invert(${p => p.inverted ? '1' : '0'});
  transition: all 111ms;
  cursor: pointer;
  border-radius: 4px;
  ${p => p.active ? '' : 'display: none;'}

  &:hover {
    background: ${colors.gray}33;
  }
`

const MenuIconWrapper = styled.div`
  border-radius: 8px;
  width: 42px;
  height: 42px;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 222ms;
`

const MenuHeader = styled.div`
  position: absolute;
  width: calc(100% - 60px);
  left: 60px;
  font-weight: bold;
  font-size: 1.25rem;
  color: ${colors.white};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  pointer-events: none;
  transition: all 222ms;
`

const MenuIcon = styled.img<{
  inRoom: boolean
}>`
  width: 32px;
  height: 32px;
  position: absolute;
  transition: all 222ms;
  transform: translateX(${p => p.inRoom ? '0px' : '-100px'});
  filter: invert(1);
  cursor: pointer;
`

const MenuCallButton = styled.div<{
  active: boolean,
  inCall: boolean
}>`
  position: relative;
  height: 24px;
  color: ${colors.white};
  white-space: pre;
  padding: 0.5rem;
  margin-right: 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  object-fit: contain;
  transition: all 111ms;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${colors.gray}${p => p.inCall ? 'FF' : '00'};
  transition: all 222ms;
  ${p => p.active ? '' : 'display: none;'}

  &:hover {
    background: ${colors.gray}33;
  }

  & span {
    margin-right: 8px;
  }
`

const MenuCallIcon = styled.img<{
  inverted: boolean
}>`
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: invert(${p => p.inverted ? '1' : '0'});
`

const MenuCallPulseAnim = keyframes`
  0% {
    width: 100%;
    height: 100%;
    transform: scale(1);
    opacity: 0; 
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0; 
  }
`

const MenuCallPulse = styled.div`
  background: ${colors.primary}88;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  animation: ${MenuCallPulseAnim} 2s linear infinite;
`


type Props = {
  appState: IAppState
  setAppState: Dispatch<SetStateAction<IAppState>>
}

interface IMenuButton {
  title: string,
  icon: string,
  inverted: boolean
  onClick: () => void,
}

const TopNav = (props: Props) => {
  let { 
    appState,
    setAppState
  } = props;

  const callActive = useRef<boolean>(false);
  const [poppedOut, setPoppedOut] = useState<boolean>(false);
  const [isPopoutWindow, setIsPopoutWindow] = useState<boolean>(false);
  const popoutWindow = useRef<Window | null>(null);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if(params.get('popout')) {
      setIsPopoutWindow(true);
    }
  }, []);

  useEffect(() => {
    // Checking Active Call
    // console.log('Checking rooms', appState.activeCalls);
    callActive.current = appState.activeCalls.includes(appState.currently.inRoom!);
  }, [appState.activeCalls])

  const OpenMenu = () => {
    setAppState(prev => {
      let asx: IAppState = {...prev}
      asx.viewPanel = 'sidenav';
      return asx;
    });
  }

  const LeaveRoom = () => {
    setAppState(prev => {
      let asx: IAppState = {...prev}
      asx.currently.leaving = true;
      return asx;
    })
  }

  const OpenChatroomSettings = () => {
    setAppState(prev => {
      let asx = {...prev}
      asx.currently.editingRoom = true;
      return asx;
    })
  }

  const GetButtons = (): string[] => {
    let buttons: string[] = [];
    // Exhibitor Mode
    let { currently, banterMode, userData, viewPanel } = appState;

    if (currently.inRoom) {
      // buttons.push('call');
    }

    if (banterMode === 'exhibitorOutreach' && userData?.metadata?.exhibitor) {
      if(viewPanel === 'users') {
        buttons.push('inbox')
      } else {
        buttons.push('users')
      }
    }
    // Normal Mode
    if (appState.banterMode === 'normal') {
      if(viewPanel === 'users') {
        buttons.push('inbox')
      } else {
        buttons.push('users')
      }
    }

    // Call Button
    if(currently.inRoom !== null && !currently.inCall) {
      for (let channel of appState.channels) {
        if (channel.channelId === currently.inRoom && channel.type === 'live') {
          buttons.push('call')
        }
      }
    }

    // Close Button
    if (window !== window.top) {
      buttons.push('close')
    }
    return buttons;
  }

  const HandleIconClose = () => {
    SendCommand('close');
  }
  const HandleIconUsers = () => {
    setAppState(prev => {
      let asx: IAppState = {...prev}
      asx.viewPanel = 'users';
      return asx;
    })
  }
  const HandleIconInbox = () => {
    setAppState(prev => {
      let asx: IAppState = {...prev}
      asx.viewPanel = null;
      return asx;
    })
  }
  const HandleIconPopout = () => {
    SendCommand('hide');
    setPoppedOut(true);
    popoutWindow.current = window.open(window.location.href+'&popout=true', undefined, "height=700,width=400,status=yes,toolbar=no,menubar=no,location=no");
  }
  const HandleIconPopin = () => {
    window.close();
    popoutWindow.current = null;
    SendCommand('show');
  }

  const HandleIconCall = async () => {
    let requestData: ARoomPMR = {
      token: appState.igniteToken || '',
      issuer: 'ignitex',
      domain: appState.igniteDomain || '', 
      name: appState.currently.inRoom || '',
      roomId: 'banter-'+appState.currently.inRoom || '',
    }
    // console.log('banter-'+appState.currently.inRoom);
    JoinARoom(requestData).then(res => {
      let { joinToken } = res;
      if (joinToken) {
        SendCommand('joinCall');
        setAppState(prev => {
          let asx = {...prev};
          asx.currently.inCall = 'https://aroom.nextechar.com?token='+joinToken
          return asx;
        })
        let newMeta = {...appState.userData!.metadata} 
        newMeta['inCall'] = true;
        Amity_ModifyUserMetadata(appState.userData!.userId, newMeta);
      }
    }).catch(err => console.error(err))
  }

  const GetHeader = (): string => {
    let { inRoom } = appState.currently;
    let { language, viewPanel } = appState;

    if (viewPanel === 'users') {
      return Translate('users', language);
    }

    if (!inRoom && viewPanel?.toString() !== 'users') {
      return Translate('messages', language);
    }

    return GetCurrentRoomName(appState);
  }

  return (
    <TopNavContainer>
      <TopNavWrapper>
        <MenuIconWrapper>
          <MenuIcon 
            inRoom={appState.currently.inRoom !== null} 
            src={LeftArrowSvg} 
            alt='' 
            onClick={LeaveRoom} 
          /> 
          <MenuIcon 
            inRoom={appState.currently.inRoom === null } 
            src={MenuIconSvg} 
            alt='' 
            onClick={OpenMenu} 
          /> 
          {/* <MenuArrow 
            style={{ width: '32px', height: '32px'}} 
            onClick={(inRoom ? LeaveRoom : OpenMenu)} 
          />
           */}
        </MenuIconWrapper>

        <MenuHeader>
          {GetHeader()}
        </MenuHeader>

        <MenuButtonWrapper className='button-wrapper'>
          <MenuCallButton active={GetButtons().includes('call')} title="Call" onClick={HandleIconCall} inCall={callActive.current}>
            {callActive.current &&
              <>
                {/* {console.log(callActive.current)} */}
                <MenuCallPulse />
              </>
            }
            <MenuCallIcon src={VideoIcon} inverted={true} />
          </MenuCallButton>
          <MenuButton active={GetButtons().includes('users')} title="Users" src={UsersIcon} onClick={HandleIconUsers} inverted={true} />
          <MenuButton active={GetButtons().includes('inbox')} title="Users" src={InboxIcon} onClick={HandleIconInbox} inverted={true} />
          <MenuButton active={GetButtons().includes('popout')} title="Pop Out" src={PopoutIcon} onClick={HandleIconPopout} inverted={true} />
          <MenuButton active={GetButtons().includes('popin')} title="Pop In" src={PopinIcon} onClick={HandleIconPopin} inverted={true} />
          <MenuButton active={GetButtons().includes('close')} title="Close" src={CloseIcon} onClick={HandleIconClose} inverted={true} />
        </MenuButtonWrapper>
      </TopNavWrapper>
    </TopNavContainer>
  )
}

export default TopNav;
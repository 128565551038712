import { ReactChildren } from 'react';
import styled from 'styled-components';
import colors from '../../utils/theme';

const Container = styled.div`
  width: calc(100% - 2rem);
  padding: 0.5rem 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const Wrapper = styled.div<{
  type: 'light' | 'dark' | 'danger'
}>`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  background: ${p => 
    p.type === 'dark' ? colors.black :
      p.type === 'light' ? colors.primary : colors.danger
  };
  color: ${p => p.type === 'dark' ? colors.white : colors.black };
  font-weight: ${p => p.type === 'dark' ? '300' : '500' };
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 3px ${p => 
    p.type === 'dark' ? colors.black :
      p.type === 'light' ? colors.primary : colors.danger
  }88; 

`

type Props = {
  type?: 'light' | 'dark' | 'danger' 
}

const FormButton: React.FC<Props> = ({
  type,
  children
}) => {

  return (
    <Container>
      <Wrapper type={type ?? 'dark'}>
        {children}
      </Wrapper>
    </Container>
  )
}

export default FormButton;
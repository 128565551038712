import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useRive } from 'rive-react';
import styled, { CSSProperties } from 'styled-components';
import IAppState from '../../@types/IAppState';
import colors from '../../utils/theme';
import ConnectionsRive from '../../media/rives/ConnectionAnimation.riv';
import { GetEnglishCharmingLoadText } from '../../utils/CharmingLoadText';
import { Translate } from '../../utils/i18nManager';

const ConnectingMessageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10000;
  pointer-events: none;
`

const ConnectingMessageWrapper = styled.div<{
  active: boolean
}>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: ${colors.white};
  background: ${colors.primary};
  transition: all 222ms;
  border-radius: ${p => p.active ? '0px' : '100px'};
  filter: ${p => p.active ? 'blur(0px)' : 'blur(20px)'};
  opacity: ${p => p.active ? '1' : '0'};
  pointer-events: ${p => p.active ? 'all' : 'none'};
`

const ConnectingMessageHeader = styled.div`
  color: ${colors.black};
  width: 80%;
  font-size: 24px;
  text-align: center;
`

const ConnecingMessageText = styled.div`
  color: ${colors.black}88;
  width: 80%;
  height: 200px;
  font-size: 18px;
  text-align: center;
`

type Props = {
  appState: IAppState,
  setAppState: Dispatch<SetStateAction<IAppState>>
}

const ConnectingMessage = (props: Props) => {

  let { connecting, language } = props.appState;

  const TextTimer = useRef<NodeJS.Timeout | null>(null);
  const [loadingText, setLoadingText] = useState<string>(GetEnglishCharmingLoadText()); 

  const { RiveComponent: ConnectionsAnimated, rive: animConnection } = useRive({
    src: ConnectionsRive,
    artboard: 'Connections',
    autoplay: true,
  })

  const connectionStyle: CSSProperties = {
    width: '100%',
    height: '100vw',
    maxHeight: '500px'
  }

  useEffect(() => {

    TextTimer.current = setInterval(() => {
      setLoadingText(GetEnglishCharmingLoadText());
    }, 3000);

    return () => {
      TextTimer.current = null;
    }
  }, [])

  return (
    <ConnectingMessageContainer>
      <ConnectingMessageWrapper active={connecting}>
        <ConnectingMessageHeader>
          {Translate('loading-connecting', language)}
        </ConnectingMessageHeader>
        <ConnectionsAnimated 
          style={connectionStyle}
        />
        {(connecting) &&
          <ConnecingMessageText>
            {loadingText}
          </ConnecingMessageText>
        }
      </ConnectingMessageWrapper>
    </ConnectingMessageContainer>
  )
}

export default ConnectingMessage;
const colors = {
  primary: '#1AAFE3',
  success: '#9DFF73',
  info: '#68DEE3',
  warning: '#FFCB75',
  danger: '#E36874',
  help: '#9F80FA',
  white: '#F1F1F1', 
  gray: '#888888',
  deep: '#2c3c4c',
  black: '#212121',
}

export default colors;

export const themes = {
  dark: {
    primary: '#1AAFE3',
    success: '#4ACFAC',
    info: '#36C7D0',
    warning: '#262833',
    danger: '#FFA48E',
    help: '#7E8CE0',
    white: '#F7F7F7', 
    gray: '#7E7E7E',
    black: '#212121',
  }, 
  light: {
    primary: '#1AAFE3',
    success: '#9DFF73',
    info: '#68DEE3',
    warning: '#FFCB75',
    danger: '#E36874',
    help: '#9F80FA',
    white: '#F1F1F1', 
    gray: '#888888',
    black: '#2c3c4c',
  }
}

import { createChannel, createMessage, createQuery, getChannels, getUser, joinChannel, queryChannelMembers, queryChannels, queryMessages, queryUsers, runQuery, updateChannel, updateUser } from "@amityco/ts-sdk";
import { rejects } from "assert";
import { resolve } from "dns";
import { Dispatch, SetStateAction } from "react";
import IAppState from "../@types/IAppState";
import { AmityCallUpdateSignature } from "./ChatUtils";

export const Amity_GetChannels = (show: string, page?: Amity.Page): Promise<Amity.Channel[]> => new Promise((resolve, reject) => runQuery(createQuery(queryChannels, {
  page
}), res => {
  // console.log('Checking for channels', res.data);
  if (res.origin === 'server') {
    if(res.data) {
      let channels: Amity.Channel[] = []
      for (let chx in res.data) {
        let channel = res.data[chx];
        // console.log(channel)
        if (channel?.metadata?.show?.includes(show) && !channel.isDeleted) {
          channels.push(res.data[chx]);
        }
      }
      resolve(channels);
    }
  }
}));

export const Amity_GetChannelMembers = (channelId: string): Promise<Amity.User[]> => new Promise((resolve, reject) => runQuery(createQuery(queryChannelMembers, {
  channelId  
}), res => {
  if (res.origin === 'server') {
    if (res.data) {
      resolve(res.data as any);
    }
  }
}))

export const Amity_GetMessages = (channelId: string, page?: Amity.Page): Promise<Amity.Message[]> => {
  let passes = 0;
  return new Promise((resolve, reject) => runQuery(createQuery(queryMessages, { channelId, page, isDeleted: false }), res => {
    let pages = res as Amity.Pages;
    // console.log('Checking for messages on channel', channelId, pages.nextPage);
    passes++;
    if (Object.keys(res.data).length > 0) {
      let messages: Amity.Message[] = []
      for (let msx in res.data) {
        messages.push(res.data[msx]);
      }
      resolve(messages);
    }
    if(passes >= 2 && Object.keys(res.data).length === 0) {
      reject('Getting Messages Failed for Channel: '+channelId);
    }
  }))
}

export const Amity_GetNextMessages = (channelId: string, page: Amity.Page): Promise<Amity.Message[]> => new Promise((resolve, reject) => runQuery(createQuery(queryMessages, { channelId, page }), res => {
  let pages = res as Amity.Pages;

  if (pages !== undefined) {

  }
}))

export const Amity_GetUsers = (page?: Amity.Page): Promise<Amity.User[]> => new Promise((resolve, reject) => runQuery(createQuery(queryUsers, {
  filter: 'all',
  page,
}), res => {
  if(res.data) {
    let users: Amity.User[] = [];
    for(let usx in res.data) {
      users.push(res.data[usx]);
    }
    // console.log(users, res.nextPage);
    resolve(users);
  }
}));

export const Amity_ModifyUserMetadata = (userId: string, newMeta: any): Promise<Amity.User> => new Promise((resolve, reject) => runQuery(createQuery(updateUser, userId, {
  metadata: newMeta
}), res => {
  if (res.data) {
    resolve(res.data);
  }
}))

export const GetSingleAmityUser = (userId: string): Promise<Amity.User> => new Promise((resolve, reject) => runQuery(createQuery(getUser, userId), (res) => {
  if(res.data) {
    resolve(res.data);
  }
}))

export const CheckInChannel = async (channelId: string, user: Amity.User) => {
  if (user.metadata?.checkins?.length) {
    let hasCheckedInBefore = false;
    for (let i = 0; i < user.metadata.checkins.length; i++) {
      let chx = user.metadata.checkins[i];
      if (chx.channelId === channelId) {
        hasCheckedInBefore = true;
        user.metadata.checkins[i].checkin = new Date();
      }
    }
    if (!hasCheckedInBefore) {
      user.metadata.checkins.push({
        channelId, 
        checkin: new Date()
      })
    }
  } else {
    user.metadata!.checkins = [];
    user.metadata!.checkins.push({
      channelId, 
      checkin: new Date()
    })
  }

  return await Amity_ModifyUserMetadata(user.userId, user.metadata);
} 

export const ClearUserMetadata = (userId: string) => new Promise((resolve, reject) => runQuery(createQuery(updateUser, userId, {
  metadata: {}
}), res => {
  if(res.data) {
    resolve(res.data.metadata);
  }
}))

export const Amity_SendMessage = (channelId: string, message: string) => new Promise((resolve, reject) => runQuery(createQuery(createMessage, {
  channelId,
  data: {
    text: message
  }
}), res => {
  if(res.data) {
    resolve(res.data);
  }
  if(res.error) {
    reject(res.error);
  }
}));

export const JoinChannel = (channelId: string) => new Promise((resolve, reject) => runQuery(createQuery(joinChannel, channelId), res => {
  if(res.data) {
    resolve(res.data);
  }
})); 

/**
 * Creates a conversation then enters it.
 * @param appState 
 * @param setAppState 
 * @param user 
 * @param updateRefs 
 */
export const CreateConversation = (
  appState: IAppState,
  setAppState: Dispatch<SetStateAction<IAppState>>,
  users: Amity.User[],
  oldMessages: Amity.Message[],
  updateRefs: (ref: 'users' | 'channels' | 'messages', newValue: any, type: 'combine' | 'overwrite', forceUpdate: boolean) => void
) => {
  setAppState(prev => {
    let asx = {...prev} 
    asx.loading = true;
    return asx;
  })
  let includeUsers: string[] = []
  let user: Amity.User | null = null;
  for (let usx of users) {
    if (usx.userId === appState.userData?.userId) {
      user = usx;
    }
    includeUsers.push(usx.userId);
  }
  Amity_CreateConversation(
    includeUsers, 
    (user) ? user.metadata?.nickname ?? user.displayName : 'New Group', 
    appState.show as string
  ).then(res => {
    Amity_GetMessages(res.channelId).then(messages => {
      // Discard existing messages
      console.log('Conversation Messages', oldMessages, messages);
      for (let i = 0; i < oldMessages.length; i++) {
        for (let j = 0; j < messages.length; j++) {
          let msx = oldMessages[i];
          let imx = messages[j];
          if (msx.messageId === imx.messageId) {
            console.log('Already exists. Removing!');
            // Remove if exists or is deleted.
            messages.splice(j, 1);
          } 
        }
      }
      updateRefs('messages', messages, 'combine', true);
    }).finally(() => {
      setAppState(prev => {
        let asx: IAppState = {...prev}
        asx.currently.inRoom = res.channelId;
        asx.loading = false;
        asx.viewPanel = null;
        return asx;
      })
    })
  })
}

const Amity_CreateConversation = (userIds: string[], displayName: string, show: string): Promise<Amity.Channel> => {
  // Check if channel exists
  const createChannelQuery = createQuery(createChannel, { 
    type: 'live', 
    userIds: userIds as any,
    metadata: { 
      show, 
      between: userIds
    }
  })
  return new Promise((resolve, reject) => {
    runQuery(createQuery(queryChannels, {
      membership: 'member',
      types: ['live'] as any,
    }), res => {
      if (res.origin === 'server') {
        if (res.data) {
          let channelExists = false;
          let currentChannel = null;
          for (let chx in res.data) {
            let currentChannel = res.data[chx];
            if (currentChannel.isDeleted) continue;
            // console.log(currentChannel);
            if (userIds.every(uid => currentChannel.metadata?.between.includes(uid))) {
              // console.log('Channel already exists.');
              channelExists = true;
              return resolve(currentChannel);
            }
          }
          if (channelExists && currentChannel) {
            resolve(currentChannel);
          } else {
            // Create if doesn't exist
            runQuery(createChannelQuery, res => {
              if (res.origin === 'server') {
                if (res.data) {
                  resolve(res.data);
                }
              }
            })
          }

        } else {
          // console.log('Creating new channel');
          // Create if doesn't exist
          runQuery(createChannelQuery, res => {
            if (res.origin === 'server') {
              if (res.data) {
                resolve(res.data);
              }
            }
          })
        }
      }

    })
  })
  
}

export const Amity_GetAllUsers = async () => await new Promise<any[]>(async (resolve, reject) => {
  let page = 0;
  let users: any[] = [];
  const GetNext = async (page: number) => {
    let tUsers = await Amity_GetUsers({after: page * 1000, limit: 1000});
    for (let usx of tUsers) {
      users.push(usx);
    }
    if (tUsers.length === 1000) {
      page++;
      await GetNext(page);
    } else {
      resolve(users);
    }
  }
  GetNext(page);
});
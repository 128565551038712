import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import IAppState from '../../@types/IAppState';
import { Amity_ModifyUserMetadata } from '../../utils/AmityManager';
import { SendCommand } from '../../utils/CommandManager';

const ARoomCallContainer = styled.div<{
  active: boolean
}>`
  position: absolute;
  top: 0; 
  right: 0; 
  bottom: 0;
  left: 0; 
  width: 100%;
  height: 100%;
  transition: all 222ms;
  pointer-events: ${p => p.active ? 'all' : 'none'};
  opacity: ${p => p.active ? '1' : '0'}; 
  background: #000000;
  z-index: 10000;
`

const ARoomCallWrapper = styled.iframe`
  position: absolute;
  top: 64px;
  bottom: 0; 
  left: 0; 
  right: 0; 
  border: none;
  width: 100%;
  height: calc(100% - 64px);
  outline: none;
`

const ARoomCallCancelWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  user-select: none;
` 

const ARoomCallCancelImg = styled.img`
  width: 32px;
  height: 32px;
  filter: invert(1);
  pointer-events: none;
`

type Props = {
  appState: IAppState
  setAppState: Dispatch<SetStateAction<IAppState>>
}

const ARoomCall = (props: Props) => {

  let { inCall } = props.appState.currently;

  const LeaveARoom = () => {
    SendCommand('leaveCall');
    props.setAppState(prev => {
      let asx = {...prev};
      asx.currently.inCall = null;
      return asx;
    })    
    Amity_ModifyUserMetadata(props.appState.userData!.userId, {...props.appState.userData!.metadata, inCall: false} );
  }

  return(
    <>
      <ARoomCallContainer active={(inCall !== null)}>
        {inCall && 
        <>
          <ARoomCallCancelWrapper onClick={() => LeaveARoom()}>
            <ARoomCallCancelImg src='https://www.svgrepo.com/show/146078/exit.svg' />
          </ARoomCallCancelWrapper>
          <ARoomCallWrapper allowFullScreen allow='microphone; camera; ' src={inCall} />
        </>
        }
      </ARoomCallContainer>
    </>
  )
}

export default ARoomCall;
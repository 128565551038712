import IAppState from "../@types/IAppState";
import StatusIconAvailable from '../media/icons/status-active.svg';
import StatusIconIdle from '../media/icons/status-idle.svg';
import StatusIconBusy from '../media/icons/status-donotdisturb.svg';
import StatusIconOffline from '../media/icons/status-invisible.svg';
import { DateTime } from "luxon";
import { SendCommand } from "./CommandManager";

export const GetCurrentRoomName = (
  appState: IAppState
): string => {
  let roomName = 'Messages'
  appState.channels.forEach(channel => {
    if(channel.channelId === appState.currently.inRoom) {
      if (channel.metadata?.between) {
        let between = channel.metadata.between;
        for (let usx of appState.users) {
          if (between.includes(usx.userId) && appState.userData?.userId !== usx.userId) {
            roomName = `@ ${usx.metadata?.nickname ?? usx.displayName}`
          }
        }
      } else {
        roomName = '@ '+channel.displayName;
      }
    }
  })
  return roomName;
}

export const GetUserStatus = (user: Amity.User): string => {
  let lastCheck = new Date(user.metadata?.lastActivity).getTime();
  let setStatus: string = user.metadata?.setStatus;

  // If the user manually set their status, return whatever it was.
  if (setStatus && setStatus !== 'auto') {
    return setStatus;
  }

  // If the user doesn't have the metadata, return unknown (which will appear as offline).
  // It should get set within a minute.
  if (!lastCheck) return 'unknown';
  
  // Check if idle or offline time has elapsed.
  let idleTime = 1000 * 60 * 15 // 15 Minutes
  let offlineTime = 1000 * 60 * 60 * 3 // 3 Hours

  if (lastCheck + offlineTime < new Date().getTime()) {
    // Set offline.
    return 'offline';
  }

  if (lastCheck + idleTime < new Date().getTime()) { // Last 15 minutes
    // Set Idle
    return 'idle'
  }

  // Otherwise, return avaiable.
  return 'available';
}

export type UserStatus = 'auto' | 'available' | 'idle' | 'do-not-disturb' | 'invisible' | 'offline' | 'unknown';
export const GetUserStatusText = (user: Amity.User): string => {
  let status = GetUserStatus(user);
  if (status === 'available') {
    return 'Available'
  }
  if (status === 'unknown' || status === 'invisible') {
    return 'Offline'
  }
  if (user.metadata?.lastActivity) {
    return 'Last Seen: '+DateTime.fromJSDate(new Date(user.metadata.lastActivity)).toRelative({base: DateTime.local()})
  }
  return 'Offline'
}

export const GetUserStatusIcon = (status: UserStatus) => {
  switch(status) {
    case 'available': return StatusIconAvailable;
    case 'idle': return StatusIconIdle;
    case 'do-not-disturb': return StatusIconBusy;
    case 'invisible': return StatusIconOffline;
    case 'offline': return StatusIconOffline;
    case 'unknown': return StatusIconOffline;
  }
}

export const SendNotifications = (appState: IAppState) => {
  let notificationCount = 0;
  let { messages, userData } = appState;
  if (userData?.metadata?.checkins) {
    for (let msx of messages) {
      for (let cix of userData.metadata.checkins) {
        if (cix.channelId === msx.channelId && new Date(msx.createdAt).getTime() > new Date(cix.checkin).getTime()) {
          notificationCount++;
        }
      }
    }   
  }
  SendCommand('setNotifications', notificationCount);
}

export const AmityCallUpdateSignature = '4f99a8c8-0ce7-4912-bd3b-cc245e322200';
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import AppSettings from '../../utils/settings'
import colors from '../../utils/theme'

const MessageContainer = styled.div<{
  author: boolean
  joint: boolean
  prejoint: boolean
}>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  margin: 0.25rem;
  ${p => p.prejoint ? '' : 'margin-bottom: 2rem;'}
  flex-direction: ${p => p.author ? 'row-reverse' : 'row'};
`

const MessageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
`

const MessageContentWrapper = styled.div<{
  author: boolean
  joint: boolean
  prejoint: boolean
}>`
  background: ${p => p.author ? colors.deep : colors.deep}88;
  width: 100%;
  color: white;
  border-radius: ${p => CalculateCorners(p.author, p.joint, p.prejoint)};
  margin: 0 0.25rem;
  padding: 0.5rem;
  white-space: pre-line;
`

const ParagraphWrapper = styled.div`
  white-space: pre-line;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const MessageIconWrapper = styled.div<{
  joint: boolean
}>`
  opacity: ${p => p.joint ? '0' : '1'};
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.white}88;
  cursor: pointer;
  overflow: hidden;
`

const MessageIconImg = styled.img<{
  joint: boolean
}>`
  width: 48px;
  height: ${p => p.joint ? '32px' : '48px'};
`

const DateTimeWrapper = styled.div<{
  author: boolean
}>`
  position: absolute;
  bottom: -1.25rem;
  left: 0; 
  right: 0;
  text-align: center;
  display: flex;
  justify-content: ${p => p.author ? 'flex-end' : 'flex-start'};
  margin: 0 5rem;
  font-size: 0.85rem;
  color: ${colors.gray};
  text-shadow: 0 0 2px ${colors.black}33;
`

const UserNameWrapper = styled.div<{
  author: boolean
}>`
  width: 100%;
  padding: 0 0.25rem;
  text-align: ${p => p.author ? 'right': 'left'};
`

const CalculateCorners = (author: boolean, joint: boolean, prejoint: boolean): string => {
  let b = { tl: 8, tr: 8, br: 8, bl: 8 } 
  if(!author) { b.tl = 0 } else { b.tr = 0 }
  if(prejoint) { b.bl = 0; b.br = 0; }
  if(joint) { b.tl = 0; b.tr = 0; }
  return `${b.tl}px ${b.tr}px ${b.br}px ${b.bl}px `;
}

type Props = {
  message: Amity.Message
  author: boolean
  userName: string
  joint: boolean
  prejoint: boolean
  openContextMenu: (userId: string) => void
}

const MessageBubble = (props: Props) => {

  const [expanded, setExpanded] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<string>('https://inferno-event-assets.s3.us-west-2.amazonaws.com/misc/jtools/blank-profile-picture.jpg');

  let {
    message,
    author,
    userName,
    joint,
    prejoint,
    openContextMenu
  } = props;

  useEffect(() => {
    let testUrl = `https://prod-eastus-ingress.inferno.jolokia.com/api/ProfileImage/${props.message.userId}/50/50`
    let image = new Image();
    image.onload = () => setUserImage(testUrl);
    image.src = testUrl;
  }, [])

  return (
    <MessageContainer 
      author={author} 
      joint={joint} 
      prejoint={prejoint}
    >
      <MessageIconWrapper 
        joint={joint}
        title={userName}
      >
        <MessageIconImg 
          joint={joint}
          onClick={(e) => {
            e.stopPropagation();
            openContextMenu(message.userId);
          }} 
          src={userImage} 
          alt=''
          onError={() => console.log('Error Loading Image')}
        />
      </MessageIconWrapper>
      <MessageWrapper>
        {!joint && <UserNameWrapper author={author}>{userName}</UserNameWrapper>}
        <MessageContentWrapper prejoint={prejoint} joint={joint} author={author}>
          <ParagraphWrapper>
            {message.data.text}
          </ParagraphWrapper>
        </MessageContentWrapper>
        <DateTimeWrapper
          author={author}
          >
          {!prejoint &&
            DateTime.fromJSDate(new Date(message.createdAt)).toRelative({base: DateTime.local()})
          }
        </DateTimeWrapper>
      </MessageWrapper>
    </MessageContainer>
  )
}

export default MessageBubble;
export const DoesModeAllow = (mode: string, feature: string): boolean => {
  let modes: any = {
    'normal': {
      'showChannels': true,
      'searchUsers': true, 
    },
    'exhibitorOutreach': {
      'showChannels': false,
      'searchUsers': false,
    }
  }

  if (!mode || !feature) return false;
  return modes[mode][feature] ?? false;

}
import styled, { CSSProperties } from 'styled-components';
import colors from '../../utils/theme';

import HoveringCube from '../../media/rives/HoveringCube.riv';
import { useRive } from 'rive-react';
import IAppState from '../../@types/IAppState';
import { Dispatch, SetStateAction } from 'react';
import { Translate } from '../../utils/i18nManager';

const Container = styled.div<{
  active: boolean
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${colors.danger};
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 100000;
  transition: all 222ms;
  pointer-events: ${p => p.active ? 'all' : 'none' };
  opacity: ${p => p.active ? '1': '0'};
  filter: blur(${p => p.active ? '0px' : '2px'});
`

const Wrapper = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
`

const TextHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 
    0 0px 1px ${colors.danger}  
  ;
  margin-bottom: 2rem;
`

const TextSubHeader = styled(TextHeader)`
  font-size: 1.5rem;
  font-weight: 500;
`

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 280px;
  padding: 0.5rem;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  background: ${colors.white}88;
  backdrop-filter: blur(4px);
  box-shadow: 0 3px 4px ${colors.black}88;
  transition: all 222ms;
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: ${colors.black};
  color: ${colors.white};
  box-shadow: 0 2px 3px ${colors.black}88;
`

type Props = {
  appState: IAppState,
  setAppState: Dispatch<SetStateAction<IAppState>>
}

const ErrorMessage = (props: Props) => {

  const {
    appState
  } = props;

  const { RiveComponent: HoveringCubeRive, rive: cube } = useRive({
    src: HoveringCube,
    artboard: 'Cube',
    autoplay: true,
  })

  const cubeStyle: CSSProperties = {
    width: '250px',
    height: '250px',
    marginRight: '10px',
  }

  const HandleReload = () => {
    window.location.reload();
  }
  
  return (
    <Container active={(appState.error !== null && appState.error !== '')}>
      <Wrapper>
        <TextHeader>{Translate('uh-oh', appState.language)}</TextHeader>
        <TextSubHeader>{Translate('something-went-wrong', appState.language)}:</TextSubHeader>
        <TextHeader>{appState.error}</TextHeader>
        <HoveringCubeRive 
          style={cubeStyle}
        />
        <TextSubHeader>
          {Translate('contact-support', appState.language)}
        </TextSubHeader>
        {/* <ButtonContainer onClick={HandleReload}>
          <ButtonWrapper>{Translate('reload', appState.language)}</ButtonWrapper>
        </ButtonContainer> */}
      </Wrapper>
    </Container>
  )
}

export default ErrorMessage;
const acceptableOrigins = [
  'ignite',
  'banter',
]

export const ParseCommand = (message: MessageEvent) => {
  if (acceptableOrigins.includes(message.origin)) {
    let data = message.data;
    switch(data.command) {

    }
  }
}

export const SendCommand = (command: string, value?: any) => {
  let searchParams = Object.fromEntries(new URLSearchParams(window.location.search))
  if (!searchParams.targetOrigin) {
    console.warn('Target origin not set. Can\'t send message to parent window.');
  }
  // console.log({origin: 'banter', command, value }, searchParams.targetOrigin )
  window.parent.postMessage({origin: 'banter', command, value }, searchParams.targetOrigin);
}
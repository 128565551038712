import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import colors from '../../utils/theme';

import BackArrow from '../../media/icons/back-arrow.svg';
import DiceIcon from '../../media/icons/dice.svg';
import UploadIcon from '../../media/icons/upload.svg';

import IAppState from '../../@types/IAppState';
import IUserData from '../../@types/IUserData';

import SwitchInput from '../inputs/SwitchInput';
import AppSettings from '../../utils/settings';
import FormInput from '../inputs/FormInput';
import { SaveUserDataLocal } from '../../utils/UserDataManager';

import StatusActive from '../../media/icons/status-active.svg';
import StatusIdle from '../../media/icons/status-idle.svg';
import StatusDND from '../../media/icons/status-donotdisturb.svg';
import StatusInvisible from '../../media/icons/status-invisible.svg';
import { Translate } from '../../utils/i18nManager';
import { createQuery, getUser, runQuery, updateUser } from '@amityco/ts-sdk';
import FormDropdown from '../inputs/FormDropdown';
import { GetUserStatus, GetUserStatusIcon, GetUserStatusText, UserStatus } from '../../utils/ChatUtils';

const SideNavOffClickTarget = styled.div<{
  active: boolean
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: all 333ms;
  pointer-events: ${p => p.active ? 'all' : 'none'};
  backdrop-filter: ${p => p.active ? 'blur(2px)' : 'blur(0px)'};
`

const SideNavContainer = styled.div<{
  open: boolean
}>`
  position: absolute;

  width: calc(100% - 40px);
  max-width: 400px;

  left: 0;
  top: 0;
  bottom: 0;

  transition: all 222ms;
  background: white;

  background: ${colors.white};

  box-shadow: 0 0 16px ${colors.black}88;

  opacity: ${p => p.open ? '1' : '0'};
  pointer-events: ${p => p.open ? 'all' : 'none'};
  transform: translateX(${p => p.open ? '0%' : '-10%'});

  z-index: 100;
`

const SideNavWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const UserImageContainer = styled.div`
  width: 100%;
  height: 128px;
  padding-top: 28px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: ${colors.black};
`

const UserImageWrapper = styled.div<{
  backgroundColor?: string
}>`
  width: 128px;
  height: 128px;
  border-radius: 16px;
  background: ${colors.primary};
  box-shadow: 0 2px 16px ${colors.primary}88;
  position: relative;
`

const UserImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
`

const BackArrowImg = styled.img`
  position: absolute;
  left: 16px; 
  top: 16px;

  width: 32px;
  height: 32px;

  filter: invert(100%);

  transition: all 111ms;
  cursor: pointer;
  user-select: none;
`

const NameContainer = styled.div`
  width: 100%;
  height: 72px;
  margin-top: -1px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  box-shadow: 0 4px 8px ${colors.black}44;
  background: ${colors.black};
  color: ${colors.white};
`

const NameWrapper = styled.div`
  margin: 1rem;
`

const SectionTitleWrapper = styled.div`
  width: 100%;
  height: 24px;
  margin: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
`

const ProfileSettingsContainer = styled.div`
  position: absolute;
  bottom: 0; 
  top: 228px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
const PermissionName = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: ${colors.white}aa;
`

const SectionSubtitleWrapper = styled.div<{
  active?: boolean
}>`
  width: calc(100% - 1rem);
  transition: all 222ms;
  z-index: 10;
  padding-left: 1rem;
  margin-top: -1rem;
  margin-bottom: ${p => p.active ? '1rem ': ''};
  userSelect: none;
  opacity: ${p => p.active ? '1': '0'};
`

const SectionSubtitle = styled.div`
  transition: all 222ms;
  color: ${colors.danger};
  z-index: 10;
  font-weight: 400;
  userSelect: none;
`

const StatusWrapper = styled.div`
  width: 36px;
  height: 36px;
  background: ${colors.black};
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 24px 0px 12px 0px;
`

const StatusIndicator = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 4px;
  bottom: 4px;
`

type Props = {
  appState: IAppState
  setAppState: Dispatch<SetStateAction<IAppState>>
}

const SideNav = (props: Props) => {

  // Grab Data from Props
  let {
    appState,
    setAppState,
  } = props;

  const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);
  const [updateState, setUpdateState] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<string>('https://inferno-event-assets.s3.us-west-2.amazonaws.com/misc/jtools/blank-profile-picture.jpg');
  const [updatingFields, setUpdatingField] = useState({
    nickname: false,
    soundOn: false,
    soundVolume: false,
    scrollToBottom: false,  
  });

  useEffect(() => {
    let testUrl = `https://prod-eastus-ingress.inferno.jolokia.com/api/ProfileImage/${appState.userData?.userId}/256/256`
    let image = new Image();
    image.onload = () => setUserImage(testUrl);
    image.src = testUrl;
  }, [appState.userData])

  // Handlers
  const CloseMenu = () => {
    // SaveUserDataLocal(appState.userData!);
    setAppState(prev => {
      let asx: IAppState = {...prev}
      asx.viewPanel = null;
      return asx;
    })
  }

  const ConstructName = (): string => {
    if (appState.userData) {
      let nickname = appState.settings?.nickname ?? null;
      let { displayName } = appState.userData;
      
      if (nickname && nickname !== '') {
        return nickname as string;
      }
      if (displayName && displayName !== '') {
        return displayName as string;
      }
      return Translate('anonymous', appState.language);
    } else {
      return 'Anonymous'
    }
  }

  useEffect(() => {
    setUpdateState(false);
  })

  // Updates the value locally.
  const HandleUserDataUpdate = (property: string, value: any) => {
    setAppState(prev => {
      let asx = prev;

      switch (property) {
        case 'nickname': asx.settings.nickname = value; break;
        case 'soundOn': asx.settings.soundOn = value; break;
        case 'soundVolume': asx.settings.soundVolume = value; break;
      }

      setNeedsUpdate(true);
      setUpdateState(true);

      return asx;
    })

  }

  const setFieldLoading = (property: string, value: boolean) => {
    setUpdatingField(prev => {
      let ufs = {...prev};
      switch (property) {
        case 'nickname': ufs.nickname = value; break;
        case 'soundOn': ufs.soundOn = value; break;
        case 'soundVolume': ufs.soundVolume = value; break;
      }
      return ufs;
    })
  }

  // Updates the value on Amity.
  const UpdateAmityUser = (property: string, value: any) => {
    // Get the current user's updated metadata
    setFieldLoading(property, true);
    runQuery(createQuery(getUser, appState.userData!.userId), res => {
      if(res.data) {
        let { metadata } = res.data;
        if (metadata) {
          switch (property) {
            case 'nickname': metadata.nickname = value.trim(); break;
            case 'soundOn': metadata.soundOn = value; break;
            case 'soundVolume': metadata.soundVolume = value; break;
            case 'scrollToBottom': metadata.scrollToBottom = value; break;
          }
          runQuery(createQuery(updateUser, appState.userData!.userId, {metadata}), res => {
            if (res.data) {
              setFieldLoading(property, false);
              setNeedsUpdate(true);
              setUpdateState(true);
            }
          })
        }
      }
    })
  }

  const GetPermissionLevelText = (): string => {
    if (appState.userData?.roles.includes('super-moderator')) return 'Super Moderator'
    if (appState.userData?.roles.includes('moderator')) return 'Moderator'
    if (appState.userData?.metadata?.exhibitor) return 'Exhibitor'
    return 'User'
  } 

  return (
    <>
      {(appState.userData !== null) &&
        <>
          <SideNavOffClickTarget 
            active={appState.viewPanel === 'sidenav'}
            onClick={(e) => {
              e.stopPropagation();
              CloseMenu();
            }}
          />
          <SideNavContainer open={appState.viewPanel === 'sidenav'}>
            <SideNavWrapper>
              {/* Profile Picture */}
              <UserImageContainer>
                <BackArrowImg 
                  title={Translate('close-menu', appState.language)}
                  src={BackArrow} 
                  alt='close menu button'
                  onClick={CloseMenu}
                />
                <PermissionName>
                  {GetPermissionLevelText()}
                </PermissionName>
                <UserImageWrapper>
                  <UserImage 
                    src={userImage}
                    alt=''
                  />
                  <StatusWrapper>
                    <StatusIndicator src={GetUserStatusIcon(GetUserStatus(appState.userData) as UserStatus)} alt='' />
                  </StatusWrapper>
                </UserImageWrapper>
              </UserImageContainer>
              {/* Display Name Container */}
              <NameContainer>
                <NameWrapper>
                  {ConstructName()}
                </NameWrapper>
              </NameContainer>
              <ProfileSettingsContainer>
                <SectionTitleWrapper>{Translate('profile-name', appState.language)}</SectionTitleWrapper>
                <FormInput 
                  maxLength={48}
                  placeholder={Translate('nickname', appState.language)}
                  value={appState.settings.nickname ?? ''}
                  autoComplete={'no'}
                  onBlur={(e: any) => UpdateAmityUser('nickname', e.target.value)}
                  onChange={(e: any) => {
                    HandleUserDataUpdate('nickname', e.target.value)
                  }}
                />

                {/* Option Inputs
                <SectionTitleWrapper>{Translate('display-name-settings', appState.language)}</SectionTitleWrapper>
                  
                  <SectionSubtitleWrapper active={appState.userData.nickname !== ''}>
                    <SectionSubtitle>
                      {'Disabled because nickname is set.'}
                    </SectionSubtitle>
                  </SectionSubtitleWrapper> */}

                <SectionTitleWrapper>{Translate('notification-settings', appState.language)}</SectionTitleWrapper>
                <SwitchInput
                    label={Translate('notification-sounds', appState.language)}
                    loading={updatingFields['soundOn']}
                    value={appState.settings.soundOn}
                    onChange={() => UpdateAmityUser('soundOn', !appState.settings.soundOn)}
                  />

                {/* <SectionTitleWrapper>{Translate('app-settings', appState.language)}</SectionTitleWrapper> */}

              </ProfileSettingsContainer>
              
            </SideNavWrapper>
          </SideNavContainer>
        </>
      }
    </>
  )
}

export default SideNav;
import { isDecorator } from "typescript"
import { IFancyLoggerTypes } from "./FancyLog";

interface IAppSettings {
  defaultProfilePictureSet: 
    'avataaars' | 'big-smile' | 'big-ears' | 
    'big-ears-neutral' | 'croodles' | 'croodles-neutral' |
    'gridy' | 'jdenticon' | 'open-peeps' |
    'identicon' | 'initials' | 'micah' | 
    'miniavs' | 'personas' | 'bottts' |  
    'pixel-art' | 'pixel-art-neutral'
    
  defaultGroupChatPictureSet: 'gridy' | 'jdenticon'
  allowProfilePictureUpload: boolean
  allowProfilePictureReroll: boolean
  jointMessageDelay: number
  maxMessageInputLines: number
  fancyLoggerTypes: IFancyLoggerTypes[]
}

/**
 * @var defaultProfilePictureSet All sets are CC and provided by https://avatars.dicebear.com/licenses
 * @var defaultGroupChatPictureSet All sets are CC and provided by https://avatars.dicebear.com/licenses
 * @var joinMessageDelay Max milliseconds between consecutive messages that a message will be considered "jointed" and appear together.
 * @var maxMessageInputLines Max number of lines the textarea is allowed before scrolling.
 */
const AppSettings: IAppSettings = {
  defaultProfilePictureSet: 'big-smile',
  defaultGroupChatPictureSet: 'jdenticon',

  allowProfilePictureUpload: false,
  allowProfilePictureReroll: true, 

  jointMessageDelay: 60000,
  maxMessageInputLines: 5,
  fancyLoggerTypes: ['all'],
}

export default AppSettings;